import React from "react";
import { Trans } from "react-i18next";

export const getCognitoErrorCode = code => {
	switch (code) {
		case "NotAuthorizedException":
			return (
				<Trans i18nKey="Cognito.NotAuthorizedException">
					Incorrect username or password.
				</Trans>
			);
		case "LimitExceededException":
			return (
				<Trans i18nKey="Cognito.limitExceededError">
					Request limit exceeded, please wait a while before trying again.
				</Trans>
			);

		default:
			return (
				<Trans i18nKey="Common.error.unknown">
					An unknown error has occurred. Please contact customer support directly.
				</Trans>
			);
	}
};
