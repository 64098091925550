import { gql } from "@apollo/client";

export default gql`
	mutation (
		$receipt: String!
		$name: String!
		$address1: String!
		$address2: String
		$city: String!
		$state: String!
		$zip: String!
		$country: String!
		$taxId: String!
	) {
		editTaxAddress(
			receipt: $receipt
			taxAddress: {
				name: $name
				address1: $address1
				address2: $address2
				city: $city
				state: $state
				zip: $zip
				country: $country
				taxId: $taxId
			}
		) {
			id
		}
	}
`;
