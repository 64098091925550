import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

import getRandomInt from "../../../util/randomID";

const { palette } = DSPalette;

const styles = {
	refundAuthFailure: {
		color: palette.error.dark,
		fontSize: "14px",
		fontWeight: "400"
	}
};

const RefundAuthFailure = ({ isResubmitRefundAllowed, setTicketModalOpen, setTicketType }) => {
	return (
		<>
			<Box display="flex" maxWidth="350px" paddingBottom="32px">
				<Typography sx={styles.refundAuthFailure}>
					{!isResubmitRefundAllowed ? (
						<Trans i18nKey="OrderDetails.authFailedMessage">
							We were unable to process the refund to your payment method. Please
							update the information for your original payment method and resubmit
							your request.
						</Trans>
					) : (
						<Trans i18nKey="OrderDetails.authPendingResubmitRefund">
							We were unable to process the refund to your payment method. Please
							resubmit your request.
						</Trans>
					)}
				</Typography>
			</Box>
			<Button
				id={"resubmitRefundRequest" + getRandomInt()}
				color="secondary"
				size="small"
				onClick={() => {
					setTicketType("requestRefund");
					setTicketModalOpen(true);
				}}
				disabled={!isResubmitRefundAllowed}
				sx={{
					height: "40px"
				}}
			>
				<Trans i18nKey="OrderDetails.resubmitRefundRequest">Resubmit Refund Request</Trans>
			</Button>
		</>
	);
};

RefundAuthFailure.propTypes = {
	isResubmitRefundAllowed: PropTypes.bool,
	setTicketModalOpen: PropTypes.func,
	setTicketType: PropTypes.func
};

export default RefundAuthFailure;
