import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

import { ReactComponent as ClickBankLogo } from "../../assets/logo/ClickBankLogoGrayscale.svg";

const styles = {
	headingText: {
		marginLeft: 1,
		fontWeight: "600",
		fontSize: 25,
		letterSpacing: 1
	}
};

const ReceiptHeading = ({ orderDate, receipt }) => {
	return (
		<Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between">
			<Box display="flex" flexDirection="column">
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					role="heading"
					aria-level={1}
				>
					<ClickBankLogo aria-label="CLICKBANK" />
					<Typography sx={styles.headingText}>
						<Trans i18nKey="Receipt.heading">RECEIPT</Trans>
					</Typography>
				</Box>
				<Typography role="heading" aria-level={2}>
					<strong>
						<Trans i18nKey="Receipt.order">Order</Trans> #:
					</strong>{" "}
					{receipt}
				</Typography>
			</Box>
			<Box>
				<Typography>
					<strong>
						<Trans i18nKey="Common.orderDate">Order Date</Trans>:
					</strong>{" "}
					{orderDate}
				</Typography>
			</Box>
		</Stack>
	);
};

ReceiptHeading.propTypes = {
	orderDate: PropTypes.string,
	receipt: PropTypes.string
};

export default ReceiptHeading;
