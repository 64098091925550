import PropTypes from "prop-types";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

import i18n from "../../i18n";

const Recaptcha = ({ forwardRef, ...rest }) => (
	<ReCAPTCHA
		ref={forwardRef}
		size="invisible"
		badge="inline"
		hl={i18n.language}
		sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
		{...rest}
	/>
);

Recaptcha.propTypes = {
	forwardRef: PropTypes.any
};

export default Recaptcha;
