import { gql } from "@apollo/client";

export default gql`
	mutation (
		$receipt: String!
		$phoneNumber: String
		$email: String!
		$fullName: String!
		$locale: String!
	) {
		editContact(
			receipt: $receipt
			contact: {
				phoneNumber: $phoneNumber
				email: $email
				fullName: $fullName
				locale: $locale
			}
		)
	}
`;
