import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { getAnonymousToken } from "../util/anonymousTokenStorage";
import { getDOLToken } from "../util/dolTokenStorage";
import { getToken } from "./Amplify";

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_GRAPHQL_URL
});

const authLink = setContext(async (_, prevContext) => {
	const cognitoToken = await getToken();
	const anonymousToken = getAnonymousToken();
	const dolToken = getDOLToken();
	const token = cognitoToken || anonymousToken || dolToken;
	const { headers, noAuth } = prevContext;
	if (!noAuth) {
		return {
			...prevContext,
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : ""
			}
		};
	}
});

export default new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache()
});
