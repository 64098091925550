import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

import { ReactComponent as Logomark } from "../../assets/logo/Logomark.svg";

const breakpoints = DSBreakpoints.default;
const styles = {
	container: {
		display: "flex",
		flexDirection: "column",
		rowGap: "1rem",
		justifyContent: "flex-start",
		width: "fit-content",
		paddingTop: "24px",

		[breakpoints.up("sm")]: {
			flexDirection: "row",
			columnGap: "36px"
		},
		"& .MuiTypography-root": {
			fontSize: 12,

			"& + .MuiTypography-body1": {
				marginTop: 0
			}
		}
	}
};

const ReceiptFooter = ({ showTaxInfo, taxCode }) => {
	return (
		<Box sx={styles.container}>
			<Logomark />
			<div>
				<Typography>ClickBank.com</Typography>
				<Typography>1444 S. Entertainment Ave</Typography>
				<Typography>Boise, ID 83709</Typography>
			</div>
			<div>
				<Typography>
					<Trans i18nKey="Receipt.tollFreePhone">Toll Free: 1-800-390-6035</Trans>
				</Typography>
				<Typography>
					<Trans i18nKey="Receipt.intlPhone">International: +1 208-345-4245</Trans>
				</Typography>
				{showTaxInfo && taxCode && (
					<Typography>
						<Trans i18nKey="Receipt.vatNumber">VAT Number</Trans>: {taxCode}
					</Typography>
				)}
			</div>
		</Box>
	);
};

ReceiptFooter.propTypes = {
	showTaxInfo: PropTypes.bool,
	taxCode: PropTypes.string
};

export default ReceiptFooter;
