const tokenName = "dolToken";

export const setDOLToken = value => {
	sessionStorage.setItem(tokenName, value);
	return tokenName;
};

export const getDOLToken = () => sessionStorage.getItem(tokenName);

export const deleteDOLToken = () => sessionStorage.removeItem(tokenName);
