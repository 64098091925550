import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

import getRandomInt from "../../../util/randomID";

const { cbNeutral, palette } = DSPalette;

export const stateBarColor = {
	REFUND_REQUESTED: palette.warning.light,
	REFUND_REQUEST_CANCELED: palette.warning.light,
	REFUND_AUTH_FAILED: palette.error.light,
	REFUNDED: palette.error.light,
	RETURNED: cbNeutral[950],
	RETURNED_REFUNDED: palette.error.light,
	CANCELED: palette.error.light,
	CANCELED_REFUNDED: palette.error.light
};

export const stateTextColor = {
	REFUND_REQUESTED: palette.warning.dark,
	REFUND_REQUEST_CANCELED: palette.warning.dark,
	REFUND_AUTH_FAILED: palette.error.dark,
	REFUNDED: palette.error.dark,
	RETURNED: cbNeutral[300],
	RETURNED_REFUNDED: palette.error.dark,
	CANCELED: palette.error.dark,
	CANCELED_REFUNDED: palette.error.dark
};

const RefundOrCancelState = ({
	lineItem,
	isRefundAuthFailState,
	isResubmitRefundAllowed,
	refundAuthDate
}) => {
	const refundTicket = lineItem.supportTickets.find(ticket => ticket.ticketType === "REFUND");
	const customerClosedTicket = refundTicket?.closedBy === "CUSTOMER";

	const getState = () => {
		// Refund Auth Failed: lineItem could be refunded or not
		if (isRefundAuthFailState) {
			return "REFUND_AUTH_FAILED";
		}
		// Resubmit Allowed: lineItem could be refunded or not
		if (isResubmitRefundAllowed) {
			return "REFUND_AUTH_FAILED";
		}
		//Refunded
		if (lineItem.refunded && !lineItem.returnShippingNotice) {
			return "REFUNDED";
		}
		//Returned
		if (!lineItem.refunded && !!lineItem.returnShippingNotice) {
			return "RETURNED";
		}
		//Returned&Refunded
		if (lineItem.refunded && lineItem.returnShippingNotice) {
			return "RETURNED_REFUNDED";
		}
		//Canceled
		if (
			!lineItem.refunded &&
			!lineItem.returnShippingNotice &&
			lineItem.paymentPlan?.activeStatus === "CANCELED"
		) {
			return "CANCELED";
		}
		//Canceled&Refunded
		if (
			(lineItem.refunded || !!lineItem.returnShippingNotice) &&
			lineItem.paymentPlan?.activeStatus === "CANCELED"
		) {
			return "CANCELED_REFUNDED";
		}
		// Cancellation Pending
		if (
			lineItem.supportTickets.some(ticket => ticket.ticketType === "CANCEL") &&
			lineItem.paymentPlan?.activeStatus !== "CANCELED"
		) {
			return "CANCELLATION_PENDING";
		}
		// Refund Requested
		if (!lineItem.refunded && refundTicket && !customerClosedTicket) {
			return "REFUND_REQUESTED";
		}
		// Refund Request Canceled
		if (!lineItem.refunded && refundTicket && customerClosedTicket) {
			return "REFUND_REQUEST_CANCELED";
		}
	};
	const colorStyle = {
		color: stateTextColor[getState()],
		fontSize: "17px",
		fontWeight: "600"
	};

	// eslint-disable-next-line no-extra-boolean-cast
	const refundDate = !!lineItem?.refundDate
		? new Date(lineItem.refundDate).toLocaleDateString("en-US")
		: null;
	// eslint-disable-next-line no-extra-boolean-cast
	const returnDate = !!lineItem?.returnShippingNotice?.shippedDate
		? new Date(lineItem.returnShippingNotice.shippedDate).toLocaleDateString("en-US")
		: null;
	// eslint-disable-next-line no-extra-boolean-cast
	const cancelDate = !!lineItem?.paymentPlan?.terminationDate
		? new Date(lineItem.paymentPlan.terminationDate).toLocaleDateString("en-US")
		: null;
	const refundFailedDate = isRefundAuthFailState
		? new Date(refundAuthDate).toLocaleDateString("en-US")
		: null;

	const cancelTicket = lineItem.supportTickets.find(ticket => ticket.ticketType === "CANCEL");
	let cancelRequestDate;
	if (cancelTicket) {
		const createTime =
			cancelTicket.actions.findLast(action => action.actionType === "CHANGED")?.createTime ??
			cancelTicket.createTime;
		cancelRequestDate = new Date(createTime).toLocaleDateString("en-US");
	}

	let refundRequestDate;
	if (refundTicket) {
		const createTime = refundTicket.createTime;
		refundRequestDate = new Date(createTime).toLocaleDateString("en-US");
	}

	const refundCanceled = customerClosedTicket && refundTicket.closedDate !== null;
	let refundCanceledDate;
	if (refundTicket && refundCanceled) {
		const closedDate = refundTicket.closedDate;
		refundCanceledDate = new Date(closedDate).toLocaleDateString("en-US");
	}

	const StateText = {
		REFUND_REQUESTED: (
			<Trans i18nKey="ProductDescription.refundRequested">
				Refund Requested on {{ refundRequestDate }}
			</Trans>
		),
		REFUND_REQUEST_CANCELED: (
			<Trans i18nKey="ProductDescription.refundRequestCanceled">
				Refund Request Canceled on {{ refundCanceledDate }}
			</Trans>
		),
		REFUNDED: <Trans i18nKey="ProductDescription.refundProcessed">Refund Processed</Trans>,
		REFUNDED_DATE: (
			<Trans i18nKey="ProductDescription.refundedOnDate">
				Refund Processed on {{ refundDate }}
			</Trans>
		),
		RETURNED: <Trans i18nKey="ProductDescription.returned">Product Returned</Trans>,
		RETURNED_DATE: (
			<Trans i18nKey="ProductDescription.returnedOnDate">
				Product Returned on {{ returnDate }}
			</Trans>
		),
		CANCELED: (
			<Trans i18nKey="ProductDescription.subscriptionCanceled">Subscription Canceled</Trans>
		),
		CANCELED_DATE: (
			<Trans i18nKey="ProductDescription.subscriptionCanceledDate">
				Subscription Canceled on {{ cancelDate }}
			</Trans>
		),
		RETURNED_REFUNDED: (
			<Trans i18nKey="ProductDescription.returnedAndRefunded">Returned & Refunded</Trans>
		),
		RETURNED_REFUNDED_DATE: (
			<Trans i18nKey="ProductDescription.returnedAndRefundedOnDate">
				Returned & Refunded on {{ refundDate }}
			</Trans>
		),
		CANCELLATION_PENDING: (
			<Trans i18nKey="ProductDescription.subCancellationPending">
				Cancellation requested on {{ cancelRequestDate }}
			</Trans>
		),
		CANCELED_REFUNDED: (
			<Trans i18nKey="ProductDescription.subscriptionCanceledAndRefunded">
				Subscription Canceled and Refunded
			</Trans>
		),
		CANCELED_REFUNDED_DATE: (
			<Trans i18nKey="ProductDescription.subCanceledNdRefundDate">
				Subscription Canceled and Refunded on {{ refundDate }}
			</Trans>
		),
		REFUND_AUTH_FAILED: (
			<Trans i18nKey="ProductDescription.refundFailed">Error Processing Refund</Trans>
		),
		REFUND_AUTH_FAILED_DATE: (
			<Trans i18nKey="ProductDescription.refundFailedOnDate">
				Error Processing Refund on {{ refundFailedDate }}
			</Trans>
		)
	};

	const showStateText = (isValidDate, stateWithDate, stateOnly) => {
		return <Typography sx={colorStyle}>{isValidDate ? stateWithDate : stateOnly}</Typography>;
	};

	const getText = () => {
		const state = getState();
		if (state === "REFUND_REQUESTED") {
			return showStateText(null, null, StateText.REFUND_REQUESTED);
		}
		if (state === "REFUND_REQUEST_CANCELED") {
			return showStateText(null, null, StateText.REFUND_REQUEST_CANCELED);
		}
		if (state === "REFUND_AUTH_FAILED") {
			return showStateText(
				!!refundFailedDate,
				StateText.REFUND_AUTH_FAILED_DATE,
				StateText.REFUND_AUTH_FAILED
			);
		}
		if (state === "REFUNDED") {
			return showStateText(!!refundDate, StateText.REFUNDED_DATE, StateText.REFUNDED);
		}
		if (state === "RETURNED") {
			return showStateText(!!returnDate, StateText.RETURNED_DATE, StateText.RETURNED);
		}
		if (state === "RETURNED_REFUNDED") {
			return showStateText(
				!!refundDate,
				StateText.RETURNED_REFUNDED_DATE,
				StateText.RETURNED_REFUNDED
			);
		}
		if (state === "CANCELED") {
			return showStateText(!!cancelDate, StateText.CANCELED_DATE, StateText.CANCELED);
		}
		if (state === "CANCELED_REFUNDED") {
			return showStateText(
				!!refundDate,
				StateText.CANCELED_REFUNDED_DATE,
				StateText.CANCELED_REFUNDED
			);
		}
		if (state === "CANCELLATION_PENDING") {
			return showStateText(true, StateText.CANCELLATION_PENDING, null);
		}
	};

	return (
		<Box
			id={"stateButton" + getRandomInt()}
			display="flex"
			alignItems="center"
			justifyContent="center"
			mt={{ xs: "24px" }}
			sx={{
				height: 48,
				padding: "0 1rem",
				backgroundColor: stateBarColor[getState()],
				borderRadius: "6px"
			}}
		>
			{getText()}
		</Box>
	);
};

RefundOrCancelState.propTypes = {
	lineItem: PropTypes.object,
	isRefundAuthFailState: PropTypes.bool,
	isResubmitRefundAllowed: PropTypes.bool,
	refundAuthDate: PropTypes.string
};

export default RefundOrCancelState;
