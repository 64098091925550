async function getMaintenanceMessages() {
	return await fetch("/maintenance-messaging.json")
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			console.error(error);
		});
}

export default getMaintenanceMessages;
