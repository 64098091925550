import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

import { currencyFormatter } from "../../../util/Currencyhelpers";
import StatusChange from "./StatusChange";
import UserComment from "./UserComment";
import VendorComment from "./VendorComment";

const styles = {
	commentThreadParent: {
		flexDirection: "row",
		paddingBottom: "2rem"
	},
	maxWidth: {
		width: "100%"
	}
};

const TicketDiscussionThread = ({ ticket, lineItem }) => {
	const roles = {
		vendor: <Trans i18nKey="TicketDiscussion.Vendor">Vendor</Trans>,
		clickbank: <Trans i18nKey="TicketDiscussion.ClickBank">ClickBank</Trans>
	};

	const getComment = (role, comment) => {
		switch (role) {
			case "CBCSR":
				return <VendorComment role={roles.clickbank} comment={comment} />;
			case "CBSYSTEM":
				return <VendorComment role={roles.clickbank} comment={comment} />;
			case "VENDOR":
				return <VendorComment role={roles.vendor} comment={comment} />;
			case "CUSTOMER":
				return <UserComment comment={comment} />;
			default:
				break;
		}
	};

	const getStatusChange = (role, action, date) => {
		const formattedDate = new Date(date).toLocaleDateString("en-US");
		switch (action) {
			case "CHANGED":
				switch (role) {
					case "CBCSR":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ChangedClickBank">
										ClickBank changed this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					case "CBSYSTEM":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ChangedClickBank">
										ClickBank changed this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					case "VENDOR":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ChangedVendor">
										The vendor changed this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					case "CUSTOMER":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ChangedCustomer">
										You changed this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					default:
						break;
				}
				break;
			case "CLOSED":
				switch (role) {
					case "CBCSR":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ClosedClickBank">
										ClickBank closed this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					case "CBSYSTEM":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ClosedClickBank">
										ClickBank closed this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					case "VENDOR":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ClosedVendor">
										The vendor closed this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					case "CUSTOMER":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ClosedCustomer">
										You closed this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					default:
						break;
				}
				break;
			case "REOPENED":
				switch (role) {
					case "CBCSR":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ReopenedClickBank">
										ClickBank reopened this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					case "CBSYSTEM":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ReopenedClickBank">
										ClickBank reopened this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					case "VENDOR":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ReopenedVendor">
										The vendor reopened this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					case "CUSTOMER":
						return (
							<StatusChange
								comment={
									<Trans i18nKey="TicketDiscussion.ReopenedCustomer">
										You reopened this ticket on {{ formattedDate }}
									</Trans>
								}
							/>
						);
					default:
						break;
				}
				break;
			case "EXPIRED":
				return (
					<StatusChange
						comment={
							<Trans i18nKey="TicketDiscussion.Expired">
								This ticket was closed due to inactivity on {{ formattedDate }}
							</Trans>
						}
					/>
				);
			default:
				break;
		}
	};

	const getEscalatedStatus = (date, key) => {
		const formattedDate = new Date(date).toLocaleDateString("en-US");
		return (
			<Box key={key} sx={styles.maxWidth}>
				<StatusChange
					comment={
						<Trans i18nKey="TicketDiscussion.EscalatedCustomer">
							You escalated this ticket to ClickBank Customer Service on{" "}
							{{ formattedDate }}
						</Trans>
					}
				/>
			</Box>
		);
	};

	const getClosingStatus = (role, date) => {
		const formattedDate = new Date(date).toLocaleDateString("en-US");
		switch (role) {
			case "CBCSR":
				return (
					<StatusChange
						comment={
							<Trans i18nKey="TicketDiscussion.FinalClickBank">
								ClickBank closed this ticket on {{ formattedDate }}
							</Trans>
						}
					/>
				);
			case "CBSYSTEM":
				return (
					<StatusChange
						comment={
							<Trans i18nKey="TicketDiscussion.FinalClickBank">
								ClickBank closed this ticket on {{ formattedDate }}
							</Trans>
						}
					/>
				);
			case "VENDOR":
				return (
					<StatusChange
						comment={
							<Trans i18nKey="TicketDiscussion.FinalVendor">
								The vendor closed this ticket on {{ formattedDate }}
							</Trans>
						}
					/>
				);
			case "CUSTOMER":
				return (
					<StatusChange
						comment={
							<Trans i18nKey="TicketDiscussion.FinalUser">
								You closed this ticket on {{ formattedDate }}
							</Trans>
						}
					/>
				);
			default:
				break;
		}
	};

	const getClosing = key => {
		return (
			<div key={key}>
				{getClosingStatus(ticket.closedBy, ticket.closedDate)}
				{getComment(ticket.closedBy, ticket.closingComment)}
			</div>
		);
	};

	const getRefund = key => {
		if (lineItem.refunded && lineItem.refunds?.length > 0) {
			const refund = lineItem.refunds[0];
			const refundDate = new Date(lineItem.refundDate).toLocaleDateString("en-US");
			const paymentMethod =
				refund.paymentMethod === "PYPL"
					? refund.paymentMethod
					: `${refund.paymentMethod.substring(0, 4)} ${refund.last4}`;
			const paymentMethodString = `: ${refundDate} (${paymentMethod}) ${currencyFormatter(
				refund.total * -1,
				refund.currencyCode
			)}`;
			const comment = (
				<>
					<Trans i18nKey="TicketDiscussion.Refunded">This transaction was REFUNDED</Trans>
					{paymentMethodString}
				</>
			);
			return <VendorComment key={key} role={roles.clickbank} comment={comment} />;
		}
	};

	const getCancellation = key => {
		if (lineItem.paymentPlan) {
			const paymentPlan = lineItem.paymentPlan;
			const cancelDate = new Date(paymentPlan.terminationDate).toLocaleDateString("en-US");
			const dateString = `: ${cancelDate}`;
			const comment = (
				<>
					<Trans i18nKey="TicketDiscussion.Canceled">This transaction was CANCELED</Trans>
					{dateString}
				</>
			);
			return <VendorComment key={key} role={roles.clickbank} comment={comment} />;
		}
	};

	const userSources = ["CUSTOMER_WAM", "API_VIRTUAL_SOURCE"];
	const vendorSources = ["VENDOR_WAM", "VENDOR_ADMIN", "API"];
	const clickbankSources = ["CSR_ADMIN", "FRAUDULENT", "KOUNT"];

	const getOpeningComment = () => {
		if (userSources.includes(ticket.ticketSource)) {
			return <UserComment comment={ticket.note} />;
		} else if (clickbankSources.includes(ticket.ticketSource)) {
			return <VendorComment role={roles.clickbank} comment={ticket.note} />;
		} else if (vendorSources.includes(ticket.ticketSource)) {
			return <VendorComment role={roles.vendor} comment={ticket.note} />;
		} else {
			return <UserComment comment={ticket.note} />;
		}
	};

	const getActions = () => {
		let dates = [];
		let nodes = {};
		const ticketActions = ticket.actions;
		ticketActions &&
			ticketActions.forEach(value => {
				const date = new Date(value.createTime);
				// if somehow two actions were simultaneous, preserve them both
				if (!nodes[date]) {
					nodes[date] = [];
					dates.push(date);
				}
				nodes[date].push(
					<Box key={`${dates.length}-${nodes[date].length}`} sx={styles.maxWidth}>
						{getStatusChange(value.role, value.actionType, value.createTime)}
						{getComment(value.role, value.note)}
					</Box>
				);
			});
		//Ability to escalate was removed from CLKBANK UI. This was left for the tickets that had already been escalated
		const escalatedDate = ticket.escalatedDate && new Date(ticket.escalatedDate);
		if (escalatedDate) {
			if (!nodes[escalatedDate]) {
				nodes[escalatedDate] = [];
				dates.push(escalatedDate);
			}
			nodes[escalatedDate].push(
				getEscalatedStatus(escalatedDate, `${dates.length}-${nodes[escalatedDate].length}`)
			);
		}
		if (lineItem.refunded && lineItem.refunds?.length > 0) {
			const refundDate = new Date(lineItem.refundDate);
			if (refundDate) {
				if (!nodes[refundDate]) {
					nodes[refundDate] = [];
					dates.push(refundDate);
				}
				nodes[refundDate].push(getRefund(`${dates.length}-${nodes[refundDate].length}`));
			}
		}
		if (lineItem.paymentPlan?.activeStatus === "CANCELED") {
			const cancelDate = new Date(lineItem.paymentPlan.terminationDate);
			if (cancelDate) {
				if (!nodes[cancelDate]) {
					nodes[cancelDate] = [];
					dates.push(cancelDate);
				}
				nodes[cancelDate].push(
					getCancellation(`${dates.length}-${nodes[cancelDate].length}`)
				);
			}
		}
		const closedDate = new Date(ticket.closedDate);
		if (closedDate) {
			if (!nodes[closedDate]) {
				nodes[closedDate] = [];
				dates.push(closedDate);
			}
			nodes[closedDate].push(getClosing(`${dates.length}-${nodes[closedDate].length}`));
		}
		return (
			dates.length > 0 &&
			dates
				.sort((a, b) => a - b)
				.map((value, index) => {
					return (
						<Box sx={styles.maxWidth} key={index}>
							{nodes[value]}
						</Box>
					);
				})
		);
	};

	return (
		<Grid container sx={styles.commentThreadParent}>
			{ticket.note && <Box sx={styles.maxWidth}>{getOpeningComment()}</Box>}
			{getActions()}
		</Grid>
	);
};

TicketDiscussionThread.propTypes = {
	ticket: PropTypes.object,
	lineItem: PropTypes.object
};

export default TicketDiscussionThread;
