import { gql } from "@apollo/client";

import { ORDER_DETAILS } from "./OrderDetails";

// receiptNo: AB62649J
export default gql`
	query ($receipt: String!) {
		orderByReceiptNo(receipt: $receipt) {
			...OrderDetailsFragment
		}
	}
	${ORDER_DETAILS}
`;
