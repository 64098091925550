import { useMutation } from "@apollo/client";
import { CircularProgress, Grid } from "@mui/material";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ApolloClient, VERIFICATION } from "../../Api/index.js";
import { setAnonymousToken } from "../../util/anonymousTokenStorage.js";

// TODO: This "page" isn't really treated as a page; possibly remove/replace.

const styles = {
	root: {
		width: "100vw",
		height: "100vh"
	}
};

export default () => {
	const history = useHistory();

	const [receiptNo, setReceiptNo] = useState(undefined);

	const handleError = ({ message }) => {
		switch (message) {
			case "BAD_CODE":
				history.push("/?status=badCode");
				break;
			case "CODE_EXPIRED":
				history.push("/?status=badCode");
				break;
			case "TOKEN_ERROR":
				history.push("/?status=tokenError");
				break;
			default:
				history.push("/?status=unknownError");
				break;
		}
	};

	const [getVerification] = useMutation(VERIFICATION, {
		client: ApolloClient,
		onError: handleError,
		onCompleted: data => {
			if (data?.verification) {
				const { exp } = jwt_decode(data.verification);
				if (exp > Date.now() / 1000) setAnonymousToken(data.verification);
				if (receiptNo) {
					history.push(`/orderDetails?receiptNo=${receiptNo}`);
				} else {
					history.push("/orderHistory");
				}
			}
		}
	});

	const verifyUser = async (email, code, receiptNo) => {
		receiptNo && setReceiptNo(receiptNo);
		await getVerification({ variables: { email, verificationCode: code } });
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const email = urlParams.get("email");
		const code = urlParams.get("code");
		const receiptNo = urlParams.get("receiptNo");

		if (email && code) {
			verifyUser(email, code, receiptNo);
		} else {
			history.push("/?status=missingUrlParamError");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Grid container sx={styles.root} justifyContent="center" alignItems="center">
			<CircularProgress />
		</Grid>
	);
};
