import { gql } from "@apollo/client";

export default gql`
	query ($vendorId: String!, $skus: [String!]!) {
		geoCurrencies(filter: "order", vendorId: $vendorId, skus: $skus) {
			countries {
				countryCode
				currencies {
					key
					value {
						accepted
						code
					}
				}
			}
		}
	}
`;
