import { gql } from "@apollo/client";

export default gql`
	mutation (
		$receipt: String!
		$kountSessionId: String!
		$billingInfo: EditShippingInput
		$shippingInfo: EditShippingInput
	) {
		editCustomer(
			receipt: $receipt
			kountSessionId: $kountSessionId
			shippingInfo: $shippingInfo
			billingInfo: $billingInfo
		)
	}
`;
