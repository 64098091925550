import * as yup from "yup";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeft } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { requestPasswordReset } from "../../Api/Amplify.js";
import { ControlledTextField } from "../../components/ControlledTextField.js";
import assetPath from "../../config/config.js";
import { requiredEmail } from "../../util/validation";

const breakpoints = DSBreakpoints.default;

const styles = {
	overLapGrid: {
		minHeight: "270px",
		marginLeft: " -70px",
		marginRight: "-60px"
	},
	textBox: {
		zIndex: 4,
		width: "100%",

		[breakpoints.up("sm")]: {
			maxWidth: "580px"
		},
		"& .MuiAlert-root": {
			marginTop: "2rem"
		}
	},
	textFieldBox: {
		width: "100%",

		[breakpoints.up("sm")]: {
			width: "480px"
		},
		"& .MuiButton-containedPrimary": {
			marginTop: "40px"
		},
		"& .backButton": {
			marginTop: "16px"
		}
	}
};

const schema = yup.object().shape({
	email: requiredEmail()
});

export default () => {
	const history = useHistory();

	const logo60 = assetPath.concat("images/Frame_60_1.png");

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [successfullyResent, setSuccessfullyResent] = useState(false);
	const [requestSent, setRequestSent] = useState(false);

	const onSubmit = async ({ email }) => {
		setLoading(true);
		try {
			await requestPasswordReset(email);
			setRequestSent(true);
			if (requestSent) {
				setSuccessfullyResent(true);
				setTimeout(() => setSuccessfullyResent(false), 5000);
			}
		} catch ({ code, message }) {
			switch (code) {
				case "LimitExceededException":
					setError(
						<Trans i18nKey="ForgotPasswordPage.limitExceededError">
							Forget Password email request limit exceeded, please wait a while before
							trying again.
						</Trans>
					);
					break;
				default:
					setError(
						<Trans i18nKey="ForgotPasswordPage.UnableToConfirmAccount">
							An unknown error occurred. Please contact customer support if this issue
							persists
						</Trans>
					);
					break;
			}
			setTimeout(() => setError(""), 5000);
		}
		setLoading(false);
	};

	const form = useForm({
		resolver: yupResolver(schema),
		mode: "onTouched"
	});

	const submit = form.handleSubmit(onSubmit);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const email = urlParams.get("email");
		if (email) {
			form.setValue("email", email, { shouldTouch: true });
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const email = form.watch("email");

	return (
		<Grid container>
			<Grid item sx={styles.textBox}>
				<Typography variant="h2" as="h1">
					<Trans i18nKey="ForgotPasswordPage.resetPasswordTitle">Reset Password</Trans>
				</Typography>

				<Typography variant="body1" sx={{ marginTop: "20px" }}>
					{requestSent ? (
						<Trans i18nKey="ForgotPasswordPage.ifRegisteredWillReceivePassword">
							An email has been sent to <strong>{{ email }}</strong> with a link to
							reset your account password. If you didn't receive the email, check the
							junk or spam folders in your inbox.
						</Trans>
					) : (
						<Trans i18nKey="ForgotPasswordPage.mainParagraph">
							Enter your email address to reset your ClickBank Customer Account
							password.
						</Trans>
					)}
				</Typography>

				{!!error && <Alert severity="error">{error}</Alert>}

				{!!successfullyResent ?? (
					<Alert severity="success">
						<Trans i18nKey="ForgotPasswordPage.successfullyResent">
							Your confirmation email has been resent, please check your email for
							next steps.
						</Trans>
					</Alert>
				)}

				<Box sx={styles.textFieldBox}>
					{!requestSent ? (
						<>
							<ControlledTextField
								control={form.control}
								id="email"
								name="email"
								label={<Trans i18nKey="Common.emailAddress">Email Address</Trans>}
								fullWidth
								sx={{ marginTop: "20px" }}
								value={email}
								onKeyDown={e => {
									if (e.key === "Enter" && !loading) {
										submit(e);
									}
								}}
							/>

							<>
								<LoadingButton
									fullWidth
									color="primary"
									id="savePasswordButton"
									disabled={loading}
									onClick={submit}
									loading={loading}
								>
									<Trans i18nKey="ForgotPasswordPage.next">Next</Trans>
								</LoadingButton>
								<Button
									fullWidth
									color="secondary"
									className="backButton"
									id="backButton"
									onClick={() =>
										history.push(
											`/login${
												email ? "?email=" + encodeURIComponent(email) : ""
											}`
										)
									}
								>
									<Trans i18nKey="ForgotPasswordPage.goBack">Cancel</Trans>
								</Button>
							</>
						</>
					) : (
						<>
							<LoadingButton
								fullWidth
								color="primary"
								id="resendEmailRequest"
								disabled={loading || successfullyResent}
								onClick={submit}
								loading={loading}
							>
								<Trans i18nKey="ForgotPasswordPage.resendEmail">Resend Email</Trans>
							</LoadingButton>
							<Button
								fullWidth
								color="secondary"
								className="backButton"
								startIcon={<ChevronLeft />}
								id="goBackToLogin"
								onClick={() =>
									history.push(
										`/login${
											email ? "?email=" + encodeURIComponent(email) : ""
										}`
									)
								}
							>
								<Trans i18nKey="Common.backToLogin">Back to Login</Trans>
							</Button>
						</>
					)}
				</Box>
			</Grid>

			<Grid item sx={{ display: { xs: "none", md: "flex" } }} sm>
				<Box sx={styles.overLapGrid}>
					<img src={logo60} alt="Customer Care" width="100%" height="100%"></img>
				</Box>
			</Grid>
		</Grid>
	);
};
