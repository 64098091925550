import * as i18n from "i18next";
import { Check, FileCopy } from "@mui/icons-material";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Trans } from "react-i18next";

export const CopyButton = ({ url }) => {
	const [copySuccess, setCopySuccess] = useState(null);

	const handleCopy = async () => {
		await navigator.clipboard.writeText(url);
		setCopySuccess(prev => {
			if (prev) {
				clearTimeout(prev);
			}
			return setTimeout(() => setCopySuccess(null), 3000);
		});
	};

	return (
		<Button
			fullWidth
			color="secondary"
			aria-label={i18n.t("Common.copyToClipboard", "Copy to clipboard")}
			onClick={handleCopy}
			sx={{ display: "flex", flexDirection: "row" }}
			startIcon={copySuccess ? <Check /> : <FileCopy />}
		>
			{copySuccess ? (
				<Trans i18nKey="Common.copied">Copied</Trans>
			) : (
				<Trans i18nKey="Common.copy">Copy</Trans>
			)}
		</Button>
	);
};

CopyButton.propTypes = {
	url: PropTypes.string.isRequired
};
export default CopyButton;
