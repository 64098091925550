import { useContext, useEffect } from "react";

import { AppContext } from "../../context";

const merchantID = process.env.REACT_APP_KOUNT_MERCHANT_ID;
const kountUrl = process.env.REACT_APP_KOUNT_ENDPOINT;
const publicUrl = process.env.PUBLIC_URL;

// Adapted from https://bitbucket.clickbank.io/projects/UI/repos/orders/browse/src/components/common/Kount.js
// Docs: https://kount.github.io/docs/dc-web-browser/
// Summary:
// Add class and attributes to body tag:
// <body class="kaxsdc" data-event="load"></body>
// Pull Kount URL and Merchant ID from .env file,
// generate Session ID, assemble into the Kount script URL.
// After page is mounted, append Kount script and kount-init.js to bottom of body
const Kount = () => {
	const { setKountSessionId } = useContext(AppContext);
	const makeSessionId = length => {
		const mask = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
		let result = "";

		// Check if crypto module is present to generate a "Strong" Unique ID
		const cryptoObj = window.crypto || window.msCrypto; // for IE 11

		if (cryptoObj) {
			// 1- Generate numbers (nb = length) between 0 and 2^32
			const randoms = cryptoObj.getRandomValues(new Uint32Array(length));
			for (let i = 0; i < randoms.length; i++) {
				// 2- Divide generated randoms by 2^32 to have a random number between 0-1
				// 3- Multiply by number of possibilities (mask length) - MAX_SAFE_INTEGER = 2^53 - 1
				// 4- Keep the integer part ( | 0).
				const index = ((mask.length * randoms[i]) / 2 ** 32) | 0;
				result += mask[index];
			}
		} else {
			for (let i = length; i > 0; --i) {
				result += mask[Math.floor(Math.random() * mask.length)];
			}
		}

		return result;
	};

	const loadScripts = (scriptPaths, callback) => {
		const loader = (src, handler) => {
			const script = document.createElement("script");
			script.src = src;

			script.onload = script.onreadystatechange = function () {
				script.onreadystatechange = script.onload = null;
				handler();
			};

			document.body.appendChild(script);
		};

		(function run() {
			if (scriptPaths.length !== 0) {
				loader(scriptPaths.shift(), run);
			} else {
				callback && callback();
			}
		})();
	};

	useEffect(() => {
		const sessionId = makeSessionId(32);
		loadScripts(
			[`${kountUrl}/collect/sdk?m=${merchantID}&s=${sessionId}`, `${publicUrl}/kountInit.js`],
			function () {
				// Wait for Kount iframe to exist and add title attribute for accessibility
				const kountObserver = new MutationObserver(() => {
					if (document.contains(document.getElementById("ibody"))) {
						document
							.getElementById("ibody")
							.setAttribute("title", "Kount Trusted Site iframe");
						kountObserver.disconnect();
					}
				});

				kountObserver.observe(document, {
					attributes: false,
					childList: true,
					characterData: false,
					subtree: true
				});

				return null;
			}
		);
		setKountSessionId(sessionId);
	}, [setKountSessionId]);

	return null;
};

export default Kount;
