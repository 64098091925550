import { DSPalette } from "@clickbank-ui/seller-design-system";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Grid, Typography } from "@mui/material";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";

import getMaintenanceMessages from "../../util/getMaintenanceMessages";

const { palette } = DSPalette;

const MaintenanceMessage = ({ active, pre }) => {
	const [loading, setLoading] = useState(true);
	const [title, setTitle] = useState();
	const [body, setBody] = useState();

	useEffect(() => {
		getMaintenanceMessages().then(data => {
			if (data) {
				setMessaging(data);
			}

			setLoading(false);
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	function setMessaging(messages) {
		const mode = active ? "maintenance" : "pre";
		const { title, message } = messages.clkbank[mode];
		/* Puts all message items except first and last into unordered list */
		const bodylist = Object.entries(message).map(([key, value]) => {
			if (!(parseInt(key) === 0 || parseInt(key) === Object.entries(message).length - 1)) {
				return <li key={key}>{value}</li>;
			}
			return null;
		});

		setTitle(title);
		setBody(
			<div>
				<p>{message[0]}</p>
				<ul>{bodylist}</ul>
				<p>{message[Object.keys(message).length - 1]}</p>
			</div>
		);
	}
	if (active || pre) {
		return (
			!loading && (
				<Box
					margin="8px"
					sx={{ backgroundColor: palette.warning.light, borderRadius: "8px" }}
				>
					<Grid
						container
						direction="row"
						spacing={0}
						sx={{ borderLeft: "8px solid #ffaf01", borderRadius: "8px" }}
					>
						<Grid item container direction="row" alignItems="center" xs={1}>
							<InfoIcon
								sx={{
									fontSize: "24px",
									color: palette.warning.main,
									marginLeft: "8px"
								}}
							/>
						</Grid>
						<Grid item xs={10} sx={{ margin: "16px 0 16px 0" }}>
							<Typography
								variant="body1"
								component="span"
								sx={{ color: palette.warning.dark, fontWeight: "700" }}
							>
								{title}&nbsp;
							</Typography>
							<Typography
								variant="body1"
								component="span"
								sx={{ color: palette.warning.dark }}
							>
								{body}
							</Typography>
						</Grid>
					</Grid>
				</Box>
			)
		);
	} else {
		return null;
	}
};

MaintenanceMessage.propTypes = {
	active: PropTypes.bool,
	pre: PropTypes.bool
};

export default MaintenanceMessage;
