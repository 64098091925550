import PropTypes from "prop-types";
import React from "react";

import OrderDetails from "../../components/OrderDetails";

const ListHistory = ({ orders, relatedOrders }) => {
	return (
		<>
			{!!orders &&
				orders.map((value, index) => (
					<OrderDetails
						key={index}
						orderDetailsInfo={value}
						collapse={false}
						showRelatedOrders={false}
					/>
				))}

			{relatedOrders.length > 0 && <OrderDetails onlyRelatedOrders={relatedOrders} />}
		</>
	);
};

ListHistory.propTypes = {
	orders: PropTypes.arrayOf(PropTypes.object),
	relatedOrders: PropTypes.arrayOf(PropTypes.object)
};

export default React.memo(ListHistory);
