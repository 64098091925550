import { DSBreakpoints, DSPalette } from "@clickbank-ui/seller-design-system";
import { ReceiptLongOutlined } from "@mui/icons-material";
import { Box, Divider, Grid, Link, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";

import { currencyFormatter } from "../../util/Currencyhelpers.js";
import { isDOLUser } from "../../util/authHelpers.js";

const { palette } = DSPalette;
const breakpoints = DSBreakpoints.default;

const styles = {
	mobileView: {
		flexDirection: "row",
		"& img": {
			marginTop: "10px",
			marginBottom: "auto"
		},
		[breakpoints.down("md")]: {
			marginLeft: "0px",
			flexDirection: "column",
			"& img": {
				marginLeft: "auto",
				marginRight: "auto",
				marginTop: "0px"
			},
			"& div": {
				width: "100%",
				maxWidth: "1000px"
			},
			"& button": {
				width: "100%"
			}
		}
	},
	recurBilCompScreen: {
		marginBottom: "32px",

		[breakpoints.down("md")]: {
			display: "none"
		},
		"& .MuiLink-root.iconLink": {
			color: palette.primary.main
		}
	},
	recurBillmobileScreen: {
		marginBottom: "32px",
		[breakpoints.up("md")]: {
			display: "none"
		}
	}
};

const RecurringBilling = ({ rebills }) => {
	const [dolUser, setDolUser] = useState(false);

	useEffect(() => {
		setDolUser(isDOLUser());
	}, []);

	return (
		<Box sx={{ display: !!rebills || rebills?.length === 0 ? "block" : "none" }}>
			<Divider />
			<Typography variant="h3" sx={{ marginTop: "24px" }}>
				<Trans i18nKey="OrderDetails.RecurBilHistory">Recurring Billing History</Trans>
			</Typography>
			<Box sx={styles.recurBilCompScreen}>
				<Grid container sx={{ margin: "32px 0px 20px" }}>
					<Grid item sm={6} md={3}>
						<Typography variant="h5" as="h4">
							<strong>
								<Trans i18nKey="Common.date">Date</Trans>
							</strong>
						</Typography>
					</Grid>
					<Grid item sm={6} md={4}>
						<Typography variant="h5" as="h4">
							<strong>
								<Trans i18nKey="Common.orderNumber">Order Number</Trans>
							</strong>
						</Typography>
					</Grid>
					<Grid item sm={6} md={2}>
						<Typography variant="h5" as="h4">
							<strong>
								<Trans i18nKey="Common.charged">Charged</Trans>
							</strong>
						</Typography>
					</Grid>
					<Grid item sm={6} md={3}>
						<div></div>
					</Grid>
				</Grid>
				{rebills?.map((item, i) => (
					<Grid container key={i} sx={{ marginBottom: "15px" }}>
						<Grid item sm={6} md={3}>
							<Typography>
								{new Date(item.orderDate).toLocaleDateString("en-US")}
							</Typography>
						</Grid>
						<Grid item sm={6} md={4}>
							<Typography>{item.receiptNo}</Typography>
						</Grid>
						<Grid item sm={6} md={2}>
							<Typography>
								{currencyFormatter(item.total, item.currencyCode)}
							</Typography>
						</Grid>
						<Grid item sm={6} md={3}>
							{!dolUser && (
								<Link
									className="iconLink"
									color="primary"
									target="_blank"
									rel="noopener noreferrer"
									onClick={() => {
										window.open(`/receipt?receiptNo=${item.receiptNo}`);
									}}
								>
									<ReceiptLongOutlined color="primary" />
									<Trans i18nKey="Common.viewReceipt">View Receipt</Trans>
								</Link>
							)}
						</Grid>
					</Grid>
				))}
			</Box>
			<Box sx={styles.recurBillmobileScreen}>
				{rebills?.map((item, index) => (
					<Grid container key={index}>
						<Grid item xs={12} sm={6}>
							<Typography variant="h5" sx={{ marginTop: "16px" }} as="h4">
								<strong>
									<Trans i18nKey="Common.date">Date</Trans>
								</strong>
							</Typography>

							<Typography sx={{ marginTop: "8px" }}>
								{new Date(item.orderDate).toLocaleDateString("en-US")}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography variant="h5" sx={{ marginTop: "16px" }} as="h4">
								<strong>
									<Trans i18nKey="Common.orderNumber">Order Number</Trans>
								</strong>
							</Typography>
							<Typography sx={{ marginTop: "8px" }}>{item.receiptNo}</Typography>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography variant="h5" sx={{ marginTop: "16px" }} as="h4">
								<strong>
									<Trans i18nKey="Common.charged">Charged</Trans>
								</strong>
							</Typography>
							<Typography sx={{ marginTop: "8px" }}>
								{currencyFormatter(item.total, item.currencyCode)}
							</Typography>
						</Grid>
						{!dolUser && (
							<Grid item xs={12}>
								<Link
									className="iconLink"
									color="primary"
									onClick={() => {
										window.open(`/receipt?receiptNo=${item.receiptNo}`);
									}}
								>
									<ReceiptLongOutlined color="primary" />
									<Trans i18nKey="Common.viewReceipt">View Receipt</Trans>
								</Link>
							</Grid>
						)}
					</Grid>
				))}
			</Box>
		</Box>
	);
};

RecurringBilling.propTypes = {
	rebills: PropTypes.arrayOf(PropTypes.object)
};

export default RecurringBilling;
