import { gql } from "@apollo/client";

export default gql`
	mutation (
		$receipt: String!
		$lineItemId: Int!
		$ticketType: TicketType!
		$reason: String!
		$comment: String
	) {
		createTicket(
			receipt: $receipt
			lineItemId: $lineItemId
			createTicketInput: { ticketType: $ticketType, reason: $reason, comment: $comment }
		)
	}
`;
