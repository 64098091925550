import { gql } from "@apollo/client";

export default gql`
	mutation (
		$email: String!
		$receipt: String
		$ccLast4: String
		$zipCode: String
		$urlPath: String!
		$language: String!
	) {
		requestVerification(
			email: $email
			receipt: $receipt
			ccLast4: $ccLast4
			zipCode: $zipCode
			urlPath: $urlPath
			language: $language
		)
	}
`;
