export const inputData = [
	{
		code: "AF",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "AX",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "AL",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "DZ",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "AS",
		zipRegex: "^\\d{5}(-{1}\\d{4,6})$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "AD",
		zipRegex: "^[Aa][Dd]\\d{3}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "AO",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "AI",
		zipRegex: "^[Aa][I][-][2][6][4][0]$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "AG",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "AR",
		zipRegex: "^\\d{4}|[A-Za-z]\\d{4}[a-zA-Z]{3}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "AM",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "AW",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "AC",
		zipRegex: "^[Aa][Ss][Cc][Nn]\\s{0,1}[1][Zz][Zz]$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "AU",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "always"
			},
			state: {}
		}
	},
	{
		code: "AT",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "AZ",
		zipRegex: "^[Aa][Zz]\\d{4}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "BS",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "BH",
		zipRegex: "^\\d{3,4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "BD",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "BB",
		zipRegex: "^[Aa][Zz]\\d{5}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "BY",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "BE",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "BZ",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "BJ",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "BM",
		zipRegex: "^[A-Za-z]{2}\\s([A-Za-z]{2}|\\d{2})$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "BT",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "BO",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "BQ",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "BA",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "BW",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "BR",
		zipRegex: "^\\d{5}-\\d{3}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "",
		zipRegex: "^[Bb][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "IO",
		zipRegex: "^[Bb]{2}[Nn][Dd]\\s{0,1}[1][Zz]{2}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "VG",
		zipRegex: "^[Vv][Gg]\\d{4}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "BN",
		zipRegex: "^[A-Za-z]{2}\\d{4}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "BG",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "BF",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "BI",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "KH",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "CM",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "CA",
		zipRegex:
			"^(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\s{0,1}\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d$",
		fields: {
			zip: {
				inputMode: "text",
				required: "always"
			},
			state: {
				required: "always"
			}
		}
	},
	{
		code: "CV",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "KY",
		zipRegex: "^[Kk][Yy]\\d[-\\s]{0,1}\\d{4}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "CF",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "TD",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "CL",
		zipRegex: "^\\d{7}\\s\\(\\d{3}-\\d{4}\\)$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "CN",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "CX",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "CC",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "CO",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "KM",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "CG",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "CD",
		zipRegex: "^[Cc][Dd]$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "CK",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "CR",
		zipRegex: "^\\d{4,5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "CI",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "HR",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "CU",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "CW",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "CY",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "CZ",
		zipRegex: "^\\d{5}\\s\\(\\d{3}\\s\\d{2}\\)$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "DK",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "DJ",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "DM",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "DO",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "TL",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "EC",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "SV",
		zipRegex: "^1101$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "EG",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GQ",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "ER",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "EE",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "ET",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "FK",
		zipRegex: "^[Ff][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "FO",
		zipRegex: "^\\d{3}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "FJ",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "FI",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "FR",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GF",
		zipRegex: "^973\\d{2}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "PF",
		zipRegex: "^987\\d{2}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "TF",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GA",
		zipRegex: "^\\d{2}\\s[a-zA-Z-_ ]\\s\\d{2}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "GM",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "GE",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "DE",
		zipRegex: "^\\d{4,5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GH",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "GI",
		zipRegex: "^[Gg][Xx][1]{2}\\s{0,1}[1][Aa]{2}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GR",
		zipRegex: "^\\d{3}\\s{0,1}\\d{2}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GL",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GD",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "GP",
		zipRegex: "^971\\d{2}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GU",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GT",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GG",
		zipRegex: "^[A-Za-z]{2}\\d\\s{0,1}\\d[A-Za-z]{2}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GN",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "GW",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "GY",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "HT",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "HM",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "HN",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "HK",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "HU",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "IS", // This is the correct country code for Iceland, but it"s coming back as "IC" from the geoCurrencues service
		zipRegex: "^\\d{3}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "IC",
		zipRegex: "^\\d{3}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "IN",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "ID",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "IR",
		zipRegex: "^\\d{5}-\\d{5}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "IQ",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "IE",
		zipRegex: "^([\\d]{5})?$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "IM",
		zipRegex: "^[Ii[Mm]\\d{1,2}\\s\\d\\[A-Z]{2}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "IL",
		zipRegex: "^\\b\\d{5}(\\d{2})?$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "IT",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "JM",
		zipRegex: "^\\d{2}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "JP",
		zipRegex: "^\\d{7}\\s\\(\\d{3}-\\d{4}\\)$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "JE",
		zipRegex: "^[Jj][Ee]\\d\\s{0,1}\\d[A-Za-z]{2}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "JO",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "KZ",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "KE",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "KI",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "KP",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "KR",
		zipRegex: "^\\d{6}\\s\\(\\d{3}-\\d{3}\\)$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "XK",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "KW",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "KG",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "LV",
		zipRegex: "^[Ll][Vv][- ]{0,1}\\d{4}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "LA",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "LB",
		zipRegex: "^\\d{4}\\s{0,1}\\d{4}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "LS",
		zipRegex: "^\\d{3}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "LR",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "LY",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "LI",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "LT",
		zipRegex: "^[Ll][Tt][- ]{0,1}\\d{5}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "LU",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MO",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "MK",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "MG",
		zipRegex: "^\\d{3}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "MW",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "MV",
		zipRegex: "^\\d{4,5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "MY",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "ML",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MT",
		zipRegex: "^[A-Za-z]{3}\\s{0,1}\\d{4}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MH",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MR",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "MU",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MQ",
		zipRegex: "^972\\d{2}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MX",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "YT",
		zipRegex: "^976\\d{2}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "FM",
		zipRegex: "^\\d{5}(-{1}\\d{4})?$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MD",
		zipRegex: "^[Mm][Dd][- ]{0,1}\\d{4}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "MC",
		zipRegex: "^980\\d{2}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MN",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "ME",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MS",
		zipRegex: "^[Mm][Ss][Rr]\\s{0,1}\\d{4}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MA",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MZ",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "MM",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "NA",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "NR",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "NP",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "NL",
		zipRegex: "^\\d{4}\\s{0,1}[A-Za-z]{2}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "NC",
		zipRegex: "^988\\d{2}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "NZ",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "NI",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "NE",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "NG",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "NU",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "NF",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "MP",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "NO",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "OM",
		zipRegex: "^\\d{3}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "PK",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "PW",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "PA",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "PG",
		zipRegex: "^\\d{3}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "PY",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "PE",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "PH",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "PN",
		zipRegex: "^[Pp][Cc][Rr][Nn]\\s{0,1}[1][Zz]{2}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "PL",
		zipRegex: "^\\d{2}[- ]{0,1}\\d{3}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "PT",
		zipRegex: "^\\d{4}([- ]{0,1}\\d{3})?$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "PR",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "QA",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "RE",
		zipRegex: "^974\\d{2}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "RO",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "RU",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "BL",
		zipRegex: "^97133$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "SH",
		zipRegex: "^[Ss][Tt][Hh][Ll]\\s{0,1}[1][Zz]{2}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "KN",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "LC",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "MF",
		zipRegex: "^97150$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "PM",
		zipRegex: "^97500$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "VC",
		zipRegex: "^[Vv][Cc]\\d{4}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "SM",
		zipRegex: "^4789\\d$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "ST",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "SA",
		zipRegex: "^\\d{5}(-{1}\\d{4})?$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "SN",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "RS",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "RS",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "SC",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "SX",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "SL",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "SG",
		zipRegex: "^\\d{2,6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "SK",
		zipRegex: "^\\d{5}\\s\\(\\d{3}\\s\\d{2}\\)$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "SI",
		zipRegex: "^([Ss][Ii][- ]{0,1}){0,1}\\d{4}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "SB",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "SO",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "ZA",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "GS",
		zipRegex: "^[Ss][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "KR",
		zipRegex: "^\\d{6}\\s\\(\\d{3}-\\d{3}\\)$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "ES",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "LK",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "SD",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "SR",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "SZ",
		zipRegex: "^[A-Za-z]\\d{3}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "SE",
		zipRegex: "^\\d{3}\\s*\\d{2}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "CH",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "SJ",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "SY",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "TW",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric"
			},
			state: {}
		}
	},
	{
		code: "TJ",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "TZ",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "TH",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "TG",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "TK",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "TO",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "TT",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "SH",
		zipRegex: "^[Tt][Dd][Cc][Uu]\\s{0,1}[1][Zz]{2}$",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "TN",
		zipRegex: "^\\d{4}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "TR",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "TM",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "TC",
		zipRegex: "^[Tt][Kk][Cc][Aa]\\s{0,1}[1][Zz]{2}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "TV",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "UG",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "UA",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "AE",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "GB",
		zipRegex: "^[A-Z]{1,2}[0-9R][0-9A-Z]?\\s*[0-9][ABDEFGHJLNPQRSTUWXYZ]{2}",
		fields: {
			zip: {
				inputMode: "text",
				required: "always"
			},
			state: {}
		}
	},
	{
		code: "US",
		zipRegex: "^\\b\\d{5}\\b(?:[- ]{1}\\d{4})?$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "always"
			},
			state: {
				required: "always"
			}
		}
	},
	{
		code: "UY",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "VI",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "UZ",
		zipRegex: "^\\d{3} \\d{3}$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "VU",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "VA",
		zipRegex: "^120$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "VE",
		zipRegex: "^\\d{4}(\\s[a-zA-Z]{1})?$",
		fields: {
			zip: {
				inputMode: "text",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "VN",
		zipRegex: "^\\d{6}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "WF",
		zipRegex: "^986\\d{2}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "YE",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	},
	{
		code: "ZM",
		zipRegex: "^\\d{5}$",
		fields: {
			zip: {
				inputMode: "numeric",
				required: "isShippable"
			},
			state: {}
		}
	},
	{
		code: "ZW",
		zipRegex: "",
		fields: {
			zip: {
				inputMode: "text"
			},
			state: {}
		}
	}
];
