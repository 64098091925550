import { Typography } from "@mui/material";
import { Trans } from "react-i18next";

export const faqList = [
	{
		question: <Trans i18nKey="FAQ.question.whatIsCLKBank">What is CLKBANK*?</Trans>,
		answer: (
			<>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.whatIsClKBank.p1">
						"CLKBANK" is the description that appears on your bank statement for
						purchases made through ClickBank. ClickBank is an established online
						marketplace that has been in operation for over 20 years. Our platform
						allows various sellers to offer a wide range of physical and digital
						products to customers worldwide. This can include items such as health foods
						and supplements, pet supplies, ebooks, courses, software, and much more.
					</Trans>
				</Typography>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.whatIsClKBank.p2">
						We pride ourselves on providing a trusted and secure environment for online
						transactions. When you see "CLKBANK" on your bank statement, you can feel
						confident that your transaction was processed safely and is backed by
						ClickBank's satisfaction guarantee.
					</Trans>
				</Typography>
			</>
		)
	},

	{
		question: (
			<Trans i18nKey="FAQ.question.moneyBack">
				How do I get my money back from ClickBank?
			</Trans>
		),
		answer: (
			<>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.moneyBack.p1">
						For transactions that are within the return period, refund requests can be
						made with the self-service options through <strong>Find My Order</strong>,
						or by contacting our Customer Service Team.
					</Trans>
				</Typography>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.moneyBack.p2">
						ClickBank upholds the satisfaction guarantee on all transactions made
						through ClickBank.
					</Trans>
				</Typography>
			</>
		)
	},

	{
		question: (
			<Trans i18nKey="FAQ.question.whyBeingCharged">
				Why am I being charged by ClickBank?
			</Trans>
		),
		answer: (
			<>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.whyBeingCharged.p1">
						If you're wondering why you see a charge from ClickBank on your bank
						statement, it's likely because you or someone in your family made a purchase
						from an online seller who uses ClickBank as their merchant. ClickBank is an
						established platform that helps various sellers offer a wide array of
						products, which could include anything from health foods and supplements,
						pet supplies, ebooks, courses, software, and much more.
					</Trans>
				</Typography>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.whyBeingCharged.p2">
						To find out more about your specific purchase, you can easily retrieve the
						details by searching under <strong>Find My Order</strong> or contacting our
						customer service team. We're here to help you with any questions or concerns
						you may have, ensuring that you have a trusted and confident shopping
						experience.
					</Trans>
				</Typography>
			</>
		)
	},

	{
		question: (
			<Trans i18nKey="FAQ.question.cancelOrder">How do I cancel a ClickBank order?</Trans>
		),
		answer: (
			<Typography variant="body2">
				<Trans i18nKey="FAQ.answer.cancelOrder">
					You have the option to cancel active subscriptions using the self-service
					features available through <strong>Find My Order</strong>, or by contacting our
					Customer Service Team. Canceling a subscription is not the same as requesting a
					refund. If you require a refund, select the "Refund" option.
				</Trans>
			</Typography>
		)
	},

	{
		question: (
			<Trans i18nKey="FAQ.question.getHelp">
				How do I get help with a product I've purchased through ClickBank?
			</Trans>
		),
		answer: (
			<>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.getHelp.p1">
						To receive product support for something you've purchased through ClickBank,
						follow these steps:
					</Trans>
				</Typography>
				<ol>
					<li>
						<Trans i18nKey="FAQ.answer.getHelp.bullet1">
							<strong>Contact the Seller:</strong> If you have questions about the
							product or need assistance with technical issues, the first point of
							contact should be the seller. The seller's contact information can
							typically be found in the receipt or through the "Find My Order"
							section. You can reach out to them directly for assistance.
						</Trans>
					</li>
					<li>
						<Trans i18nKey="FAQ.answer.getHelp.bullet2">
							<strong>Use the "Find My Order" Section:</strong> To access the seller's
							contact information or open a technical support ticket, utilize the
							"Find My Order" section. This feature enables you to retrieve the
							details you need to get in touch with the seller or submit a technical
							support request.
						</Trans>
					</li>
					<li>
						<Trans i18nKey="FAQ.answer.getHelp.bullet3">
							<strong>Contact Our Customer Support Team:</strong> If, after attempting
							to contact the seller, you are unable to obtain the necessary support or
							resolution for your purchase, please don't hesitate to reach out to our
							dedicated customer support team. We're here to assist you and ensure
							your satisfaction with the products you've acquired through ClickBank.
						</Trans>
					</li>
				</ol>
			</>
		)
	},

	{
		question: (
			<Trans i18nKey="FAQ.question.recognizePurchase">
				What if I don't recognize my purchase?
			</Trans>
		),
		answer: (
			<>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.recognizePurchase.p1">
						We understand that seeing an unfamiliar purchase on your bank statement can
						be concerning, but we're here to assist you in resolving any uncertainties.
						Here are steps you can take to address this situation:
					</Trans>
				</Typography>
				<ol>
					<li>
						<Trans i18nKey="FAQ.answer.recognizePurchase.bullet1">
							<strong>Search Your Email for a Receipt:</strong> To identify the
							purchase, start by searching your email for a receipt. An email
							containing details of your purchase would have been sent to you at the
							time of the transaction. We recommend searching your email for keywords
							like "CLKBANK" or "noreply@clickbank.com." Additionally, make sure to
							check your email's Trash and Spam folders.
						</Trans>
					</li>
					<li>
						<Trans i18nKey="FAQ.answer.recognizePurchase.bullet2">
							<strong>Utilize the "Find My Order" Section:</strong> In our "Find My
							Order" section, you can access the details of your order. To use the
							search, you'll need to provide the email address used for the order,
							along with one of the following: the order number, billing zip code, or
							the last 4 digits of the credit card number associated with the
							transaction.
						</Trans>
					</li>
					<li>
						<Trans i18nKey="FAQ.answer.recognizePurchase.bullet3">
							<strong>Contact Our Customer Support Team:</strong> If you've tried the
							above steps and still have questions or concerns, don't hesitate to
							contact our customer support team, by chat, phone, or email. We are
							ready to assist you and provide additional guidance to help you identify
							the purchase and address any issues. Your peace of mind is important to
							us, and we're committed to ensuring your satisfaction and resolving any
							uncertainties regarding your purchase.
						</Trans>
					</li>
				</ol>
			</>
		)
	},

	{
		question: (
			<Trans i18nKey="FAQ.question.lookUpOrder">How do I look up a ClickBank order?</Trans>
		),
		answer: (
			<Typography variant="body2">
				<Trans i18nKey="FAQ.answer.lookUpOrder">
					You can use <strong>Find My Order</strong> to look up your order details using
					your email address and the order number, or contact our Customer Service by
					chat, phone, or email.
				</Trans>
			</Typography>
		)
	},

	{
		question: (
			<Trans i18nKey="FAQ.question.whatIsOrderNumber">
				What is my ClickBank order number?
			</Trans>
		),
		answer: (
			<>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.whatIsOrderNumber.p1">
						To locate your order number, it will appear as a series of 8 letters and
						numbers. If your transaction on your card or payment statement displays as
						"CLKBANK*COM," simply look for the series of 8 letters and numbers that
						follow this description.
					</Trans>
				</Typography>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.whatIsOrderNumber.p2">
						If your transaction displays as "CLKBANK*[something else]," your order
						number is also included in the email receipt sent to you at the time of
						purchase. To locate it, we recommend searching your email for "CLKBANK" or
						"noreply@clickbank.com" to help you find the email receipt containing your
						order number. Make sure to check your email's Trash and Spam folders.
					</Trans>
				</Typography>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.whatIsOrderNumber.p3">
						If you're still unable to locate your order number, you can also try
						searching by the last 4 digits of your card and the billing postal code
						associated with the transaction within the "Find My Order" section.
						Alternatively, you can contact our dedicated Customer Service Team for
						further assistance.
					</Trans>
				</Typography>
			</>
		)
	},

	{
		question: <Trans i18nKey="FAQ.question.secure">Is ClickBank secure?</Trans>,
		answer: (
			<>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.secure.p1">
						Absolutely! ClickBank is committed to providing a secure and trustworthy
						online shopping experience for our customers. We employ several measures to
						safeguard your information including:
					</Trans>
				</Typography>
				<ul>
					<li>
						<Trans i18nKey="FAQ.answer.secure.bullet1">
							<strong>SSL Encryption:</strong> All interactions with our website are
							protected by Secure Socket Layer (SSL) encryption. This technology
							ensures that your data, such as payment information and personal
							details, is transmitted securely.
						</Trans>
					</li>
					<li>
						<Trans i18nKey="FAQ.answer.secure.bullet2">
							<strong>Data Protection:</strong> We take extreme precautions to protect
							your sensitive information. Our systems are designed to prevent
							unauthorized access and data breaches.
						</Trans>
					</li>
					<li>
						<Trans i18nKey="FAQ.answer.secure.bullet3">
							<strong>Payment Security:</strong> ClickBank processes payments securely
							and follows industry-standard security practices. We do not store your
							credit card details on our servers, and our payment processing complies
							with Payment Card Industry Data Security Standard (PCI DSS)
							requirements. This means that your payment information is highly secure
							during the transaction process.
						</Trans>
					</li>
				</ul>
			</>
		)
	},

	{
		question: <Trans i18nKey="FAQ.question.safeToUse">Is ClickBank safe to use?</Trans>,
		answer: (
			<>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.safeToUse.p1">
						ClickBank is a trusted global online marketplace, committed to providing a
						secure and reliable online shopping experience for customers around the
						world. Our checkout process is designed with robust security measures,
						including the latest encryption and authentication standards, to safeguard
						the confidentiality of web transactions and protect our customers.
					</Trans>
				</Typography>
				<Typography variant="body2">
					<Trans i18nKey="FAQ.answer.safeToUse.p2">
						Additionally, we uphold a satisfaction guarantee for all transactions
						processed through ClickBank. This means that you can shop with confidence,
						knowing that we prioritize your satisfaction and security throughout the
						entire checkout process. Your peace of mind is our top priority, and we're
						committed to ensuring your safety and satisfaction with every purchase.
					</Trans>
				</Typography>
			</>
		)
	}
];
