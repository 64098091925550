import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";

import { checkCognitoTokenExists, isAuthenticated } from "./authHelpers";

export const PrivateRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuthenticated(location) ? (
					<Component />
				) : (
					<Redirect
						to={{
							pathname: "/",
							search: window.location.search,
							state: { from: location }
						}}
					/>
				)
			}
		/>
	);
};

PrivateRoute.propTypes = {
	component: PropTypes.elementType.isRequired
};

export const PublicOnlyRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={({ location }) =>
				!checkCognitoTokenExists() ? (
					<Component />
				) : (
					<Redirect
						to={{
							pathname: "/orderHistory",
							search: window.location.search,
							state: { from: location }
						}}
					/>
				)
			}
		/>
	);
};

PublicOnlyRoute.propTypes = {
	component: PropTypes.elementType.isRequired
};
