import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Box, Link, TableCell, TableRow, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import {
	statusBarColor,
	statusTextColor,
	supportTicketStatus,
	supportTicketType
} from "../../constants";
import { getTicketStatus } from "../../util/orderHelpers";

const { cbNeutral } = DSPalette;

const styles = {
	tableCell: {
		borderBottom: "0px",
		padding: "4px",
		color: cbNeutral["200"]
	},
	firstTableCell: {
		padding: "4px 4px 4px 0px"
	},
	lastTableCell: {
		padding: "4px 0px 4px 4px"
	},
	statusBox: {
		textAlign: "center"
	}
};

const RelatedTicket = ({
	setCurrentTicket,
	setCurrentLineItem,
	setCurrentReceipt,
	setTicketAction,
	setComment,
	ticket,
	lineItem,
	receipt,
	index
}) => {
	const formattedDate = new Date(ticket.createTime).toLocaleDateString("en-US");
	const isTicketProcessed =
		lineItem?.refunded || lineItem?.paymentPlan?.activeStatus === "CANCELED";
	const status = getTicketStatus(ticket, isTicketProcessed);

	return (
		<TableRow key={index} sx={{ verticalAlign: "top" }}>
			<TableCell sx={{ ...styles.tableCell, ...styles.firstTableCell }}>
				<Link
					component="button"
					id="resendCode"
					onClick={() => {
						setCurrentLineItem(lineItem);
						setCurrentReceipt(receipt);
						setCurrentTicket(ticket);
						setTicketAction(ticket.status === "CLOSED" ? "reopen" : "reply");
						setComment("");
					}}
				>
					<Typography color="primary">#{ticket.id}</Typography>
				</Link>
			</TableCell>
			<TableCell sx={styles.tableCell}>{receipt}</TableCell>
			<TableCell sx={styles.tableCell}>{supportTicketType[ticket.ticketType]}</TableCell>
			<TableCell sx={styles.tableCell}>{formattedDate}</TableCell>
			<TableCell sx={{ ...styles.tableCell, ...styles.lastTableCell }}>
				<Box sx={{ ...styles.statusBox, background: statusBarColor[status] }} px="8px">
					<Typography sx={{ color: statusTextColor[status], fontSize: "1.1667rem" }}>
						{supportTicketStatus[status]}
					</Typography>
				</Box>
			</TableCell>
		</TableRow>
	);
};

RelatedTicket.propTypes = {
	setCurrentTicket: PropTypes.func,
	setCurrentLineItem: PropTypes.func,
	setCurrentReceipt: PropTypes.func,
	setTicketAction: PropTypes.func,
	setComment: PropTypes.func,
	ticket: PropTypes.object,
	lineItem: PropTypes.object,
	receipt: PropTypes.string,
	index: PropTypes.number
};

export default RelatedTicket;
