import jwt_decode from "jwt-decode";

import { getCurrentUserDetails } from "../Api/Amplify";
import { getAnonymousToken } from "./anonymousTokenStorage";
import { deleteDOLToken, getDOLToken } from "./dolTokenStorage";

export const getEmailFromToken = async () => {
	const cognitoUser = await getCurrentUserDetails();
	if (cognitoUser) {
		if (cognitoUser.attributes) {
			const { email } = cognitoUser.attributes;
			return email;
		}
	}

	const anonymousToken = getAnonymousToken();
	if (anonymousToken) {
		const { email } = jwt_decode(anonymousToken);
		return email;
	}

	const dolToken = getDOLToken();
	if (dolToken) {
		const { email } = jwt_decode(dolToken);
		return email;
	}

	return undefined;
};

export const getSubFromToken = async () => {
	const cognitoUser = await getCurrentUserDetails();
	if (cognitoUser) {
		if (cognitoUser.attributes) {
			const { sub } = cognitoUser.attributes;
			return sub;
		}
	}

	return undefined;
};
export const isAnonymousUser = () => !!getAnonymousToken();

export const checkCognitoTokenExists = () =>
	!!Object.keys(sessionStorage).find(item => item.includes(".accessToken"));

export const isAuthenticated = location => {
	return isAnonymousUser() || checkCognitoTokenExists() || isDOLAuthenticated(location?.search);
};

const authErrorMessage = "Request failed with status code 403";

export const isAuthenticationError = error => {
	return (
		error.message === "UNAUTHORIZED" ||
		error.networkError?.result?.errors?.filter(e => e.message === authErrorMessage)
	);
};

export const getReceiptFromDolToken = () => {
	const dolToken = getDOLToken();
	if (dolToken) {
		const { receipt } = jwt_decode(dolToken);
		return receipt;
	}
	return "NO_RECEIPT_FOUND";
};

export const isDOLUser = () => !!getDOLToken();

const isDOLAuthenticated = searchParams => {
	const urlParams = new URLSearchParams(searchParams);
	const urlReceipt = urlParams.get("receiptNo");
	const key = urlParams.get("key");
	const hasURLReceipt = key && key.length === 20 && !urlParams.get("receiptNo");
	const tokenReceipt = getReceiptFromDolToken();
	if (tokenReceipt && (!hasURLReceipt || urlReceipt === tokenReceipt)) {
		return true;
	} else {
		deleteDOLToken();
		return false;
	}
};
