/*
	This hook is used to get the maintenance mode status.
	It returns the following:
	- loading: true if the hook is still loading
	- active: true if the maintenance mode is active
	- pre: true if the pre-maintenance mode is active
*/
import { useEffect, useState } from "react";

import IsFeatureEnabledForUser from "./IsFeatureEnabledForUser";

function useMaintenanceMode() {
	const [loadingMaintenanceMode, setLoadingMaintenanceMode] = useState(true);
	const [isCurrentMaintenance, setCurrentMaintenance] = useState(false);
	const [isPreMaintenance, setPreMaintenance] = useState(false);

	const isMaintenanceMode = IsFeatureEnabledForUser("enable-clkbank-maintenance-mode");
	const isPreMaintenanceMode = IsFeatureEnabledForUser("enable-clkbank-pre-maintenance-mode");
	const isGlobalMaintenanceMode = IsFeatureEnabledForUser("enable-all-apps-maintenance-mode");
	const isGlobalPreMaintenanceMode = IsFeatureEnabledForUser(
		"enable-all-apps-pre-maintenance-mode"
	);

	useEffect(() => {
		if (
			typeof isMaintenanceMode === "boolean" &&
			typeof isPreMaintenanceMode === "boolean" &&
			typeof isGlobalMaintenanceMode === "boolean" &&
			typeof isGlobalPreMaintenanceMode === "boolean"
		) {
			setCurrentMaintenance(isMaintenanceMode || isGlobalMaintenanceMode);
			setPreMaintenance(isPreMaintenanceMode || isGlobalPreMaintenanceMode);
			setLoadingMaintenanceMode(false);
		}
	}, [
		isMaintenanceMode,
		isPreMaintenanceMode,
		isGlobalMaintenanceMode,
		isGlobalPreMaintenanceMode
	]);

	return { loading: loadingMaintenanceMode, active: isCurrentMaintenance, pre: isPreMaintenance };
}

export default useMaintenanceMode;
