import { AuthStorage } from "../util/authStorage";

export default {
	awsRegion: "us-west-2",
	cognitoRegion: "us-west-2",
	userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
	userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
	type: "dev",
	storage: AuthStorage,
	scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
	loglevel: "DEBUG"
};
