import { ChevronLeft } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Trans } from "react-i18next";

const VerificationPanel = ({
	verificationCode,
	resetFormFn,
	setVerificationCode,
	verification,
	verificationLoading,
	receiptLoading,
	emailLoading,
	requestVerification
}) => {
	const [showErrorMessage, setShowErrorMessage] = useState(false);

	const onChange = e => {
		const { value } = e.target;
		if (!value) return setVerificationCode("");
		setVerificationCode(value);
	};

	return (
		<Box marginBottom="7%" className="ms-form">
			<TextField
				fullWidth
				id="verification-code"
				label={<Trans i18nKey="Common.verificationCode">Verification Code</Trans>}
				value={verificationCode}
				onChange={onChange}
				onKeyDown={event => {
					if (event.key === "Enter" && verificationCode?.length === 6) {
						verification();
					}
				}}
				onBlur={() => setShowErrorMessage(verificationCode?.length < 1)}
				error={showErrorMessage}
				helperText={
					showErrorMessage ? (
						<Trans i18nKey="VerificationPanel.enterCode">
							Please enter a verification code.
						</Trans>
					) : (
						""
					)
				}
				inputProps={{ maxLength: 6 }}
			/>
			<Typography variant="body2">
				<Link
					component="button"
					id="resendCode"
					onClick={() => {
						requestVerification();
					}}
					underline="none"
					sx={{ marginLeft: "3px" }}
				>
					<Trans i18nKey="VerificationPanel.resendCodeLink">Resend Code</Trans>
				</Link>
			</Typography>
			<LoadingButton
				fullWidth
				id="submitVerificationButton"
				color="primary"
				disabled={
					verificationCode?.length < 6 ||
					verificationLoading ||
					receiptLoading ||
					emailLoading
				}
				onClick={() => {
					verification();
				}}
				sx={{ marginTop: "30px" }}
				loading={verificationLoading || receiptLoading || emailLoading}
			>
				<Trans i18nKey="VerificationPanel.submitCodeButton">Submit Verification Code</Trans>
			</LoadingButton>
			<Box sx={{ margin: "48px 0" }}>
				<Button
					size="small"
					color="secondary"
					startIcon={<ChevronLeft />}
					id="backtoOrderLookup"
					onClick={() => resetFormFn()}
					// TODO: Once the Lookup Form is abstracted, this should just re-render the form component.
				>
					<Trans i18nKey="Common.backToOrderLookup">Back to Order Lookup</Trans>
				</Button>
			</Box>
		</Box>
	);
};

VerificationPanel.propTypes = {
	verificationCode: PropTypes.string,
	resetFormFn: PropTypes.func,
	setVerificationCode: PropTypes.func,
	verification: PropTypes.func,
	verificationLoading: PropTypes.bool,
	receiptLoading: PropTypes.bool,
	emailLoading: PropTypes.bool,
	requestVerification: PropTypes.func
};

export default VerificationPanel;
