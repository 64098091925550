import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans, getI18n } from "react-i18next";

const RecurringBillingState = ({ lineItem, hasBeenRebilled, options }) => {
	if (lineItem.paymentPlan?.activeStatus === "ACTIVE") {
		const getTrialLastSubText = () => {
			return lineItem.subtotal > 0 ? (
				<Trans i18nKey="OrderDetails.trialLastSubRenewText">
					Trial Period Ends & Last Subscription Renews
				</Trans>
			) : (
				<Trans i18nKey="OrderDetails.freeTrialLastSubRenewText">
					Free Trial Period Ends & Last Subscription Renews
				</Trans>
			);
		};

		const getTrialText = () => {
			return lineItem.subtotal > 0 ? (
				<Trans i18nKey="OrderDetails.trialSubRenewText">
					Trial Period Ends & Subscription Renews
				</Trans>
			) : (
				<Trans i18nKey="OrderDetails.freeTrialSubRenewText">
					Free Trial Period Ends & Subscription Renews
				</Trans>
			);
		};

		return (
			<div>
				<Typography align="center" sx={{ fontSize: "14px", marginTop: "10px" }}>
					{!hasBeenRebilled && lineItem.product.priceModel?.trial ? (
						lineItem.paymentPlan.paymentsRemaining === 1 ? (
							getTrialLastSubText()
						) : (
							getTrialText()
						)
					) : lineItem.paymentPlan?.paymentsRemaining === 1 ? (
						<Trans i18nKey="OrderDetails.lastSubRenewText">
							Last Subscription Renews
						</Trans>
					) : (
						<Trans i18nKey="OrderDetails.subRenewText">Subscription Renews</Trans>
					)}
				</Typography>
				<Typography variant="h5" align="center">
					{new Date(lineItem.paymentPlan?.nextBillDate).toLocaleDateString(
						`${getI18n()?.language}-US`,
						options
					)}
				</Typography>
			</div>
		);
	} else if (lineItem.paymentPlan?.activeStatus === "COMPLETED") {
		return (
			<>
				<Typography variant="h5" sx={{ alignSelf: "center" }}>
					<Trans i18nKey="productDescription.subscriptionExpired">
						Subscription Expired
					</Trans>
				</Typography>
			</>
		);
	}
};

RecurringBillingState.propTypes = {
	lineItem: PropTypes.object,
	options: PropTypes.object,
	hasBeenRebilled: PropTypes.bool
};

export default RecurringBillingState;
