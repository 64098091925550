import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Trans, getI18n } from "react-i18next";

import { RESEND_RECEIPT } from "../../Api";
import { ApolloClient } from "../../Api";
import { taxName } from "../../constants";
import { AppContext } from "../../context/AppContext";
import { currencyFormatter } from "../../util/Currencyhelpers.js";
import getRandomInt from "../../util/randomID";

const OrderSummary = ({ orderDetailsInfo }) => {
	const country = orderDetailsInfo?.shippingCustomer?.country;

	const taxType = () => {
		if (taxName.gst.includes(country))
			return (
				<>
					<Trans i18nKey="OrderDetails.gst">GST</Trans>:
				</>
			);
		else if (taxName.vat.includes(country))
			return (
				<>
					<Trans i18nKey="OrderDetails.vat">VAT</Trans>:
				</>
			);
		return (
			<>
				<Trans i18nKey="Common.taxes">Taxes</Trans>:
			</>
		);
	};
	const { setAlertText, showAlert, setAlert, setActionSuccessful } = useContext(AppContext);

	const email = orderDetailsInfo?.billingCustomer?.email;

	const isProductsShippable = orderDetailsInfo?.lineItems?.some(
		item => item.product.shippable === true
	);

	const couponItem = orderDetailsInfo?.lineItems?.filter(item => item.coupon !== null)[0];

	const message = {
		SUCCESS: (
			<Trans i18nKey="OrderSummary.successMessage">
				An email receipt has been sent to the following email address: {{ email }}
			</Trans>
		),
		FAILED: (
			<Trans i18nKey="OrderSummary.failedMessage">
				An error has occurred. An email receipt was unable to be sent to the following email
				address: {{ email }}
			</Trans>
		)
	};

	const handleReceiptError = () => {
		!showAlert && setAlert(true);
		setAlertText(message.FAILED);
		setActionSuccessful(false);
	};

	const handleReceiptSuccess = () => {
		!showAlert && setAlert(true);
		setAlertText(message.SUCCESS);
		setActionSuccessful(true);
	};

	const [resendReceiptAction, { loading: receiptLoading }] = useMutation(RESEND_RECEIPT, {
		variables: { receipt: orderDetailsInfo?.receiptNo, language: getI18n()?.language },
		client: ApolloClient,
		onError: handleReceiptError,
		onCompleted: handleReceiptSuccess
	});

	return (
		<Box sx={{ marginBottom: "28px" }}>
			<Divider sx={{ margin: "32px 0" }} />
			<Grid container direction="row" spacing={8}>
				<Grid item xs={12} md={6} lg={7}>
					<Typography variant="h3" sx={{ marginBottom: "32px" }}>
						<Trans i18nKey="Common.orderSummary">Order Summary</Trans>
					</Typography>

					<Box>
						<Box display="flex" justifyContent="space-between">
							<Typography variant="h5" sx={{ fontWeight: "normal" }} as="h4">
								<Trans i18nKey="Common.subtotal">Subtotal</Trans>:
							</Typography>
							<Typography variant="h5" sx={{ fontWeight: "normal" }} as="h4">
								{currencyFormatter(
									orderDetailsInfo?.subtotal,
									orderDetailsInfo?.currencyCode
								)}
							</Typography>
						</Box>

						{isProductsShippable && (
							<Box display="flex" justifyContent="space-between" marginTop="24px">
								<Typography variant="h5" sx={{ fontWeight: "normal" }} as="h4">
									<Trans i18nKey="Common.shippingHandling">
										Shipping & Handling
									</Trans>
									:
								</Typography>
								<Typography variant="h5" sx={{ fontWeight: "normal" }} as="h4">
									{orderDetailsInfo?.shipping === 0 ? (
										<Trans i18nKey="OrderSummary.free">Free</Trans>
									) : (
										currencyFormatter(
											orderDetailsInfo?.shipping,
											orderDetailsInfo?.currencyCode
										)
									)}
								</Typography>
							</Box>
						)}
						{couponItem && (
							<Box display="flex" justifyContent="space-between" marginTop="24px">
								<Typography variant="h5" sx={{ fontWeight: "normal" }} as="h4">
									{couponItem?.coupon.code} -{" "}
									<Trans i18nKey="OrderDetails.couponValue">
										{{ value: couponItem?.coupon.value }}% OFF
									</Trans>
								</Typography>
								<Typography variant="h5" sx={{ fontWeight: "normal" }}>
									-{" "}
									{currencyFormatter(
										orderDetailsInfo?.discounts,
										orderDetailsInfo?.currencyCode
									)}
								</Typography>
							</Box>
						)}
						<Box marginTop="24px" marginBottom="18px">
							<Box display="flex" justifyContent="space-between">
								<Typography variant="h5" sx={{ fontWeight: "normal" }} as="h4">
									{taxType()}
								</Typography>
								<Typography variant="h5" sx={{ fontWeight: "normal" }} as="h4">
									{currencyFormatter(
										orderDetailsInfo?.taxes,
										orderDetailsInfo?.currencyCode
									)}
								</Typography>
							</Box>
						</Box>
						<Divider />
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							marginTop="24px"
						>
							<Typography variant="h5" as="h4">
								<Trans i18nKey="Common.total">Total</Trans>:
							</Typography>
							<Typography variant="h3">
								{" "}
								{currencyFormatter(
									orderDetailsInfo?.total,
									orderDetailsInfo?.currencyCode
								)}
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} lg={5}>
					<Box sx={{ "& .MuiButton-root": { margin: "1rem 0 0" } }}>
						<Button
							fullWidth
							color="secondary"
							id={"viewReceipt1" + getRandomInt()}
							onClick={() => {
								window.open(`/receipt?receiptNo=${orderDetailsInfo.receiptNo}`);
							}}
						>
							<Trans i18nKey="Common.viewReceipt">View Receipt</Trans>
						</Button>
						<LoadingButton
							fullWidth
							color="secondary"
							id={"resendEmail" + getRandomInt()}
							disabled={showAlert || receiptLoading}
							onClick={() => {
								resendReceiptAction();
							}}
							loading={receiptLoading}
						>
							<Trans i18nKey="OrderDetails.resendEmailReceiptButton">
								Resend Email Receipt
							</Trans>
						</LoadingButton>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

OrderSummary.propTypes = {
	orderDetailsInfo: PropTypes.object
};

export default OrderSummary;
