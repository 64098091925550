import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { confirmSignUp, resendSignUp } from "../../Api/Amplify.js";

const styles = {
	root: {
		width: "100%",
		height: "80vh"
	}
};

export default () => {
	const history = useHistory();

	const verifyUser = async (email, code) => {
		try {
			await confirmSignUp(email, code);
			return history.push(`/login?status=signUpSuccess&email=${encodeURIComponent(email)}`);
		} catch ({ code, message }) {
			switch (code) {
				case "ExpiredCodeException":
					await resendSignUp(email);
					return history.push(
						`/login?status=signUpResent&email=${encodeURIComponent(email)}`
					);
				case "NotAuthorizedException":
					if (message.includes("CONFIRMED")) {
						return history.push(
							`/login?status=signUpSuccess&email=${encodeURIComponent(email)}`
						);
					}
					return;
				default:
			}
			return history.push(`/login?status=signUpFailed&email=${encodeURIComponent(email)}`);
		}
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const email = urlParams.get("email");
		const code = urlParams.get("code");
		if (email && code) {
			verifyUser(email, code);
		} else {
			history.push(`/login?status=signUpFailed&email=${encodeURIComponent(email)}`);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Grid container sx={styles.root} justifyContent="center" alignItems="center">
			<CircularProgress />
		</Grid>
	);
};
