export class AuthStorage {
	// the promise returned from sync function
	static syncPromise = null;

	// set item with the key
	static setItem(key, value) {
		sessionStorage.setItem(key, value);

		return key;
	}

	// get item with the key
	static getItem = key => sessionStorage.getItem(key);

	// remove item with the key
	static removeItem = key => sessionStorage.removeItem(key);

	// clear out the storage
	static clear = () => sessionStorage.clear();

	// If the storage operations are async(i.e AsyncStorage)
	// Then you need to sync those items into the memory in this method
	static sync = () =>
		new Promise(resolve => {
			resolve();
		});
}
