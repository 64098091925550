export const getTicketStatus = (ticket, isProcessed) => {
	let _status;
	//Ability to escalate was removed from CLKBANK UI. This was left for the tickets that had already been escalated
	switch (ticket?.status) {
		case "OPEN":
			_status = ticket?.escalatedDate ? "ESCALATED" : "OPEN";
			return _status;
		case "REOPENED":
			_status = ticket?.escalatedDate ? "ESCALATED" : "OPEN";
			return _status;
		case "CLOSED":
			_status = ticket?.escalatedDate ? "ESCALATED" : "CLOSED";
			_status = isProcessed ? "PROCESSED" : _status;
			return _status;
		default:
			return "OPEN";
	}
};

const hasClosedRefundTicket = lineItem =>
	lineItem.supportTickets?.some(
		ticket => ticket.ticketType === "REFUND" && ticket.status === "CLOSED"
	);
const hasOpenRefundTicket = lineItem =>
	lineItem.supportTickets?.some(
		ticket => ticket.ticketType === "REFUND" && ticket.status === "OPEN"
	);
const hasMultipleClosedRefundTickets = lineItem =>
	lineItem.supportTickets?.filter(
		ticket => ticket.ticketType === "REFUND" && ticket.status === "CLOSED"
	)?.length > 1;

// two cases here (ORD-1862):
// 1) refunded + multiple closed refund tickets - partial refund case from bug
// 2) not refunded + at least one closed refund ticket + no open refund tickets
const isLineItemRefundAuthFailState = lineItem =>
	!hasOpenRefundTicket(lineItem) &&
	(lineItem.refunded
		? hasMultipleClosedRefundTickets(lineItem)
		: hasClosedRefundTicket(lineItem));

export const isRefundAuthFailState = (orderDetailsInfo, lineItem) =>
	orderDetailsInfo?.refundStatus === "REFUND_AUTH_FAIL" &&
	(lineItem
		? isLineItemRefundAuthFailState(lineItem)
		: orderDetailsInfo?.lineItems?.some(li => isLineItemRefundAuthFailState(li)));

export const isResubmitRefundAllowed = (orderDetailsInfo, lineItem) =>
	orderDetailsInfo?.refundStatus === "REFUND_AUTH_PENDING" &&
	(lineItem
		? isLineItemRefundAuthFailState(lineItem)
		: orderDetailsInfo?.lineItems?.some(li => isLineItemRefundAuthFailState(li)));
