import { DSPalette } from "@clickbank-ui/seller-design-system";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

import RelatedTicket from "./RelatedTicket";

const { cbNeutral } = DSPalette;

const styles = {
	tableCell: {
		borderBottom: "0px",
		padding: "4px",
		color: cbNeutral["200"]
	},
	firstTableCell: {
		padding: "4px 4px 4px 0px"
	},
	lastTableCell: {
		padding: "4px 0px 4px 4px"
	}
};

const RelatedTickets = ({
	currentTicketId,
	setCurrentTicket,
	setCurrentLineItem,
	setCurrentReceipt,
	setComment,
	setTicketAction,
	relatedTickets
}) => {
	const getRelatedTickets = () => {
		return relatedTickets.map((value, index) => {
			return value.ticket.id === currentTicketId ? null : (
				<RelatedTicket
					setCurrentTicket={setCurrentTicket}
					setCurrentLineItem={setCurrentLineItem}
					setCurrentReceipt={setCurrentReceipt}
					setTicketAction={setTicketAction}
					setComment={setComment}
					ticket={value.ticket}
					lineItem={value.lineItem}
					receipt={value.receipt}
					key={index}
				/>
			);
		});
	};

	return (
		<Box paddingY="8px">
			<Typography variant="h5" sx={{ padding: "8px 0" }}>
				<Trans i18nKey="RelatedTickets.titleText">Related Tickets</Trans>
			</Typography>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow sx={{ verticalAlign: "top" }}>
							<TableCell sx={{ ...styles.tableCell, ...styles.firstTableCell }}>
								<Trans i18nKey="RelatedTickets.supportTicket">Support Ticket</Trans>
							</TableCell>
							<TableCell sx={styles.tableCell}>
								<Trans i18nKey="Common.orderNumber">Order Number</Trans>
							</TableCell>
							<TableCell sx={styles.tableCell}>
								<Trans i18nKey="RelatedTickets.ticketType">Ticket Type</Trans>
							</TableCell>
							<TableCell sx={styles.tableCell}>
								<Trans i18nKey="RelatedTickets.creationDate">Creation Date</Trans>
							</TableCell>
							<TableCell sx={{ ...styles.tableCell, ...styles.lastTableCell }}>
								<Trans i18nKey="RelatedTickets.ticketStatus">Ticket Status</Trans>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>{getRelatedTickets()}</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

RelatedTickets.propTypes = {
	currentTicketId: PropTypes.string,
	setCurrentTicket: PropTypes.func,
	setCurrentLineItem: PropTypes.func,
	setCurrentReceipt: PropTypes.func,
	setTicketAction: PropTypes.func,
	setComment: PropTypes.func,
	relatedTickets: PropTypes.arrayOf(PropTypes.object)
};

export default RelatedTickets;
