import { NetworkStatus } from "@apollo/client";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { Alert, Box, CircularProgress, Grid, MenuItem, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { signOut } from "../../Api/Amplify";
import NoOrderIcon from "../../assets/noOrder.svg";
import NotFindingOrder from "../../components/NotFindingOrder";
import PaginatedButtonGroup from "../../components/PaginatedButtonGroup";
import { dateRangeKeys, dateRangeOptions } from "../../constants/index";
import { AppContext } from "../../context";
import { isAuthenticationError } from "../../util/authHelpers";
import ContactCardsPanel from "../OrderLookup/ContactCardsPanel";
import HistoryList from "./HistoryList";

const breakpoints = DSBreakpoints.default;

const styles = {
	buttonGroupBottom: {
		"@media (maxWidth:700px)": {
			marginRight: "24px"
		}
	},
	dateRange: {
		maxWidth: 200,

		[breakpoints.up("xs")]: {
			maxWidth: 200
		}
	},
	orderDetailsText: {
		padding: "0 0 1.5rem",

		[breakpoints.up("sm")]: {
			maxWidth: 680,
			padding: "1rem 0"
		}
	}
};

export default () => {
	const pageSize = 5;

	const history = useHistory();

	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [lastMonths, setLastMonths] = useState(1);
	const [firstOrderCalled, setFirstOrderCalled] = useState(false);
	const [firstOrderLoaded, setFirstOrderLoaded] = useState(false);

	const [orderSplitedArray, setOrderSplitedArray] = useState([]);
	const [relatedOrderArray, setRelatedOrderArray] = useState([]);
	const [newHistoryCall, setNewHistoryCall] = useState(true);
	const [hasLoaded, setHasLoaded] = useState(false);

	const NoOrderComponent = () => (
		<Box
			display="flex"
			justifyContent="center"
			flexDirection="column"
			margin="auto"
			maxWidth="400px"
			minHeight="400px"
		>
			<img src={NoOrderIcon} alt="" style={{ height: "100px", marginBottom: "1rem" }} />
			<Typography variant="body1" align="center">
				<Trans i18nKey="orderHistory.noOrders">
					There are no orders during this timeframe associated with this account.
				</Trans>
			</Typography>
		</Box>
	);

	const {
		getOrderHistory,
		ordersHistoryData,
		ordersHistoryError,
		historyLoading,
		orderHistoryStatus,
		setLastDate,
		alertText,
		setAlert,
		setAlertText,
		showAlert,
		isActionSuccessful,
		email,
		getEmail,
		setUpdatedOrders,
		getFirstOrderError,
		getFirstOrderData,
		getFirstOrderLoading,
		getFirstOrder
	} = useContext(AppContext);

	useEffect(() => {
		getEmail();
		setUpdatedOrders([]);
		setNewHistoryCall(true);
		setFirstOrderCalled(false);
		if (email) {
			getFirstOrder();
		}
	}, [email]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showAlert) {
			setTimeout(() => {
				setAlert(false);
				setAlertText("");
			}, 60000);
		}
	}, [showAlert, setAlert, setAlertText]);

	useEffect(() => {
		if (
			orderHistoryStatus === NetworkStatus.refetch ||
			orderHistoryStatus === NetworkStatus.setVariables
		) {
			setNewHistoryCall(true);
		}
		if (orderHistoryStatus === NetworkStatus.loading) {
			setHasLoaded(true);
		}
	}, [orderHistoryStatus]);

	const splitOrders = (totalPages, orders) => {
		let tempArray = [];
		let tempRelatedArray = [];
		for (let index = 0; index < totalPages; index++) {
			let element = orders.slice(index * pageSize, (index + 1) * pageSize);
			let arr = [];
			element.map(x => {
				if (x.relatedOrders) arr = arr.concat(x.relatedOrders);
				return arr;
			});
			tempRelatedArray.push(arr);
			tempArray.push(element);
		}

		return [tempArray, tempRelatedArray];
	};

	const setDateRange = useCallback(
		m => {
			let newValue = m;
			if (newValue < dateRangeOptions[0]) newValue = dateRangeOptions[0];
			if (newValue > dateRangeOptions[dateRangeOptions.length - 1])
				newValue = dateRangeOptions[dateRangeOptions.length - 1];
			let newLastDate = new Date();
			newLastDate.setMonth(newLastDate.getMonth() - newValue);
			setLastMonths(newValue);
			setLastDate(newLastDate);
		},
		[setLastDate]
	);

	useEffect(() => {
		!firstOrderLoaded && getFirstOrderLoading && setFirstOrderLoaded(true);
		if (
			firstOrderLoaded &&
			!firstOrderCalled &&
			!getFirstOrderLoading &&
			getFirstOrderData?.ordersByEmail
		) {
			setFirstOrderCalled(true);
			if (getFirstOrderData.ordersByEmail.orders.length > 0) {
				const orderDate = new Date(getFirstOrderData.ordersByEmail.orders[0].orderDate);
				const monthsDifference = new Date().getMonth() - orderDate.getMonth();
				const yearsDifference = new Date().getFullYear() - orderDate.getFullYear();
				const totalDifference = monthsDifference + yearsDifference * 12;
				for (let index = 0; index < dateRangeOptions.length; index++) {
					if (totalDifference < dateRangeOptions[index]) {
						setDateRange(dateRangeOptions[index]);
						getOrderHistory();
						return;
					}
				}
				setDateRange(dateRangeOptions[dateRangeOptions - 1]);
				getOrderHistory();
			} else {
				// no orders found, default to max range
				setDateRange(dateRangeOptions[dateRangeOptions.length - 1]);
			}
		}
		if (firstOrderLoaded && getFirstOrderError) {
			if (isAuthenticationError(getFirstOrderError)) {
				const handleAuthError = async () => {
					await signOut();
					history.push("/?status=AuthenticationError");
				};
				handleAuthError();
			}
		}
	}, [
		getFirstOrder,
		getFirstOrderError,
		getFirstOrderLoading,
		getOrderHistory,
		firstOrderLoaded,
		setFirstOrderLoaded,
		getFirstOrderData,
		history,
		setDateRange,
		firstOrderCalled
	]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	}, [currentPage]);

	useEffect(() => {
		showAlert &&
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
	}, [showAlert]);

	useEffect(() => {
		if (
			orderHistoryStatus === NetworkStatus.ready &&
			ordersHistoryData?.ordersByEmail &&
			newHistoryCall
		) {
			const totalPages =
				ordersHistoryData?.ordersByEmail?.orders.length % pageSize === 0
					? parseInt(ordersHistoryData?.ordersByEmail?.orders.length / pageSize)
					: parseInt(ordersHistoryData?.ordersByEmail?.orders.length / pageSize) + 1;

			let [ordersSplited, relatedOrders] = splitOrders(
				totalPages,
				ordersHistoryData?.ordersByEmail?.orders
			);
			setOrderSplitedArray(ordersSplited);
			setRelatedOrderArray(relatedOrders);

			setTotalPages(totalPages);
			if (totalPages < currentPage) {
				setCurrentPage(totalPages > 0 ? totalPages : 1);
			}
			setNewHistoryCall(false);
		}
		if (hasLoaded && ordersHistoryError) {
			if (isAuthenticationError(ordersHistoryError)) {
				const handleAuthError = async () => {
					await signOut();
					history.push("/?status=AuthenticationError");
				};
				handleAuthError();
			}
		}
	}, [
		ordersHistoryData,
		ordersHistoryError,
		currentPage,
		newHistoryCall,
		history,
		orderHistoryStatus,
		hasLoaded
	]);

	const selectTimeChanged = e => {
		setDateRange(e.target.value);
		getOrderHistory();
		setNewHistoryCall(true);
	};

	return (
		<>
			<Typography variant="h1">
				<Trans i18nKey="OrderHistoryPage.orderHistoryTitle">Your Order History</Trans>
			</Typography>
			<Typography sx={styles.orderDetailsText}>
				<Trans i18nKey="OrderHistoryPage.orderHistoryParagraph">
					The order history for <strong className="no-mouseflow"> {{ email }}</strong> is
					listed below. On behalf of our sellers, affiliates, and ClickBank team, we
					appreciate your business.
				</Trans>
			</Typography>

			<Box sx={styles.dateRange}>
				<TextField
					select
					fullWidth
					id="select-time"
					label={<Trans i18nKey="orderHistory.orderPlacedIn">Order Placed in</Trans>}
					value={lastMonths}
					onChange={e => selectTimeChanged(e)}
				>
					{dateRangeOptions.map((value, index) => (
						<MenuItem value={value} key={index}>
							<Typography variant="body2">{dateRangeKeys[value]}</Typography>
						</MenuItem>
					))}
				</TextField>
			</Box>
			{showAlert && (
				<Box sx={{ maxWidth: "680px", margin: "1rem 0 2rem" }}>
					<Alert severity={isActionSuccessful ? "success" : "error"}>{alertText}</Alert>
				</Box>
			)}
			<div>
				{historyLoading || getFirstOrderLoading || !firstOrderCalled ? (
					<Box
						minHeight="400px"
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<CircularProgress />
					</Box>
				) : orderSplitedArray.length === 0 ? (
					<NoOrderComponent />
				) : (
					<>
						<HistoryList
							orders={orderSplitedArray[currentPage - 1]}
							relatedOrders={relatedOrderArray[currentPage - 1]}
						/>
						<Box
							display="flex"
							marginTop="6px"
							justifyContent="center"
							sx={styles.buttonGroupBottom}
						>
							<PaginatedButtonGroup
								totalPages={totalPages}
								currentPageParent={currentPage}
								setCurrentPageParent={setCurrentPage}
							/>
						</Box>
					</>
				)}
			</div>

			<Grid container spacing={5}>
				<Grid item md={4}>
					<NotFindingOrder />
				</Grid>
				<Grid item md={8}>
					<ContactCardsPanel />
				</Grid>
			</Grid>
		</>
	);
};
