import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { isAuthenticated } from "../../util/authHelpers.js";

const styles = {
	root: {
		width: "100vw",
		height: "100vh"
	}
};

export default () => {
	const history = useHistory();

	const extractReceiptNo = rcpt => {
		const hyphenCount = rcpt.split("-").length - 1;
		switch (hyphenCount) {
			case 0:
				return rcpt.length >= 8 ? rcpt.substring(rcpt.length - 8, rcpt.length) : undefined;
			case 1:
				return rcpt.length >= 13
					? rcpt.substring(rcpt.length - 13, rcpt.length)
					: undefined;
			case 2:
				return rcpt.length >= 18
					? rcpt.substring(rcpt.length - 18, rcpt.length)
					: undefined;
			default:
				return undefined;
		}
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const rcpt = urlParams.get("rcpt") || urlParams.get("gotoRcpt");
		if (rcpt) {
			const receiptNo = extractReceiptNo(rcpt);
			if (receiptNo) {
				if (isAuthenticated()) {
					return history.push(`/orderDetails?receiptNo=${receiptNo}`);
				} else {
					return history.push(`/?receiptNo=${receiptNo}`);
				}
			}
		}
		history.push("/?status=missingUrlParamError");
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Grid container sx={styles.root} justifyContent="center" alignItems="center">
			<CircularProgress />
		</Grid>
	);
};
