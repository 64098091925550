import { DSPalette, DSTypography } from "@clickbank-ui/seller-design-system";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import i18next from "i18next";
import IntlTelInput from "intl-tel-input/reactWithUtils";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";

const { cbBlue, cbNeutral, palette } = DSPalette;
const { primaryFont } = DSTypography;

const styles = {
	telInput: {
		marginTop: "4px",

		"& .iti": {
			width: "100%",

			"& input.iti__search-input, & input.iti__tel-input": {
				border: `solid 1px ${cbNeutral[700]}`,
				borderRadius: "4px",
				fontFamily: primaryFont,
				fontSize: "1rem",
				color: cbNeutral[300],

				"&:hover": {
					border: `solid 1px ${cbBlue[500]}`,
					boxShadow: "0 0 4px rgba(44, 32, 223, 0.5)"
				},
				"&:focus": {
					outline: "none"
				},
				"&::placeholder": {
					color: cbNeutral[700]
				}
			},
			"& input.iti__search-input": {
				width: "calc(100% - 16px)",
				height: 36,
				margin: "8px",
				padding: "0 11px "
			},
			"& input.iti__tel-input": {
				width: "100%",
				height: 48,
				padding: "19px 18px 4px 11px"
			},
			"& .iti__selected-dial-code": {
				margin: "0 0 -2px",
				fontFamily: primaryFont,
				fontSize: "1rem",
				color: cbNeutral[300],
				lineHeight: 1
			}
		},
		"& .iti--inline-dropdown .iti__dropdown-content": {
			width: "100% !important",
			marginTop: "1px",
			background: "white",
			border: `solid 1px ${cbNeutral[900]}`,
			boxShadow: "0px 2px 4px rgba(14, 13, 38, 0.24)",
			borderRadius: "4px"
		},
		"& .iti__selected-country": {
			alignItems: "end",
			paddingBottom: "9px"
		},
		"& .iti__selected-country-primary": {
			alignItems: "end",
			paddingLeft: "11px"
		},
		"& .iti__arrow": {
			marginBottom: "3px"
		},
		"& .iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country-primary:hover, .iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country:has(+ .iti__dropdown-content:hover) .iti__selected-country-primary":
			{
				backgroundColor: "transparent"
			},
		"& .iti__country-container": {
			width: "100%"
		},
		"& .iti__country": {
			margin: "0 8px",
			padding: "6px 11px",
			borderRadius: "4px",
			fontFamily: primaryFont,
			fontSize: "1rem",
			color: cbNeutral[300],
			whiteSpace: "nowrap",

			"&.iti__highlight": {
				backgroundColor: cbBlue[950]
			}
		},
		// Error state
		"&.error": {
			"& input.iti__tel-input": {
				borderColor: palette.error.main,
				color: palette.error.main,

				"&:hover": {
					borderColor: palette.error.main,
					boxShadow: "none"
				}
			},
			"& .iti__selected-dial-code": {
				color: palette.error.main
			}
		}
	}
};

const errorMap = [
	i18next.t("EditInfoModal.error.invalidPhoneNumber", "Invalid phone number"),
	i18next.t("EditInfoModal.error.invalidCountryCode", "Invalid country code."),
	i18next.t("EditInfoModal.error.phoneTooShort", "Phone number is too short."),
	i18next.t("EditInfoModal.error.phoneTooLong", "Phone number is too long."),
	i18next.t("EditInfoModal.error.invalidPhoneNumber", "Invalid phone number.")
];

const IntlPhoneInput = forwardRef((props, ref) => {
	const { form, callback, oldPhoneNumber } = props;

	const [newPhoneNumber, setNewPhoneNumber] = useState("");
	const [phoneIsValid, setPhoneIsValid] = useState(null);
	const [phoneErrorCode, setPhoneErrorCode] = useState(null);

	const [errorCode, setErrorCode] = useState(null);

	useEffect(() => {
		callback(phoneIsValid);
	}, [callback, phoneIsValid]);

	useEffect(() => {
		form.setValue("phoneNumber", newPhoneNumber);
		if (!phoneIsValid) {
			const errorMessage = errorMap[phoneErrorCode || 0] || (
				<Trans i18nKey="EditInfoModal.error.invalidPhoneNumber">
					Invalid phone number.
				</Trans>
			);
			setErrorCode(errorMessage);
		}
	}, [form, newPhoneNumber, phoneErrorCode, phoneIsValid]);

	return (
		<FormControl
			margin="normal"
			fullWidth
			sx={styles.telInput}
			className={!phoneIsValid ? "error" : ""}
		>
			<InputLabel
				shrink
				sx={{
					...(!phoneIsValid && { color: palette.error.main })
				}}
			>
				<Trans i18nKey="Common.label.phoneNumber">Phone Number</Trans>
			</InputLabel>
			<Controller
				control={form.control}
				name="phoneNumber"
				render={({ field }) => (
					<IntlTelInput
						{...field}
						ref={ref}
						id="phoneNumber"
						initialValue={oldPhoneNumber}
						onChangeNumber={setNewPhoneNumber}
						onChangeValidity={setPhoneIsValid}
						onChangeErrorCode={setPhoneErrorCode}
						initOptions={{
							initialCountry: "us",
							separateDialCode: true,
							countryOrder: ["us", "ca"]
						}}
					/>
				)}
			/>
			<FormHelperText sx={{ mt: 0.25, lineHeight: 1.2 }}>
				{!phoneIsValid && (
					<>
						<span style={{ color: palette.error.main }}>{errorCode}</span>
						<br />
						<br />
					</>
				)}
				<Trans i18nKey="EditInfoModal.helperText.phoneSms">
					If you opted into SMS during checkout, new phone numbers provided will be
					automatically enrolled to receive SMS. Only U.S. phone numbers are eligible for
					SMS.
				</Trans>
			</FormHelperText>
		</FormControl>
	);
});

IntlPhoneInput.propTypes = {
	form: PropTypes.object,
	callback: PropTypes.func,
	oldPhoneNumber: PropTypes.string
};

export default IntlPhoneInput;
