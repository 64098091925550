import { gql } from "@apollo/client";

export default gql`
	mutation ($lineItemId: ID!, $trackingNumber: String!, $carrier: String!) {
		createCustomerReturn(
			lineItemId: $lineItemId
			trackingNumber: $trackingNumber
			carrier: $carrier
		) {
			id
			carrier
			shippedDate
			trackingNumber
			trackingUrl
			comments
			received
		}
	}
`;
