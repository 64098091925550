import { gql } from "@apollo/client";

export default gql`
	query ($country: String!, $zipCode: String!) {
		findLocation(country: $country, zipCode: $zipCode, filter: "order") {
			states
			cities
		}
	}
`;
