import { DSPalette } from "@clickbank-ui/seller-design-system";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

import { faqList } from "../../util/faq";

const breakpoints = DSBreakpoints.default;
const { cbNeutral } = DSPalette;

const styles = {
	accordionList: {
		"& .MuiAccordion-root": {
			borderBottom: "none",
			borderRadius: 0,

			"&:first-of-type": {
				borderRadius: 0
			},
			"&:last-of-type": {
				borderBottom: `solid 1px ${cbNeutral[900]}`,
				borderRadius: 0
			},

			"&.Mui-expanded": {
				margin: "0 0 .5rem"
			}
		},
		"& .MuiAccordionSummary-root": {
			minHeight: 64,
			padding: "0 1rem",

			"&.Mui-expanded": {
				minHeight: 64
			}
		},
		"& .MuiAccordionDetails-root": {
			padding: "1rem",
			backgroundColor: cbNeutral[950],

			"& .MuiTypography-root": {
				lineHeight: "1.2",

				[breakpoints.up("md")]: {
					lineHeight: "1.75"
				}
			},
			"& li": {
				paddingBottom: "1rem",
				lineHeight: 1.65
			}
		}
	}
};

const FAQPanel = () => {
	return (
		<Box sx={styles.accordionList}>
			<Typography variant="h2" sx={{ paddingBottom: "1.25rem" }}>
				<Trans i18nKey="Common.faq">Frequently Asked Questions</Trans>
			</Typography>
			{faqList.map((faq, index) => (
				<Accordion key={index}>
					<AccordionSummary>
						<Typography variant="body2">{faq.question}</Typography>
					</AccordionSummary>
					<AccordionDetails>{faq.answer}</AccordionDetails>
				</Accordion>
			))}
		</Box>
	);
};

export default FAQPanel;
