import { Trans } from "react-i18next";

// Error messages related to lookup form
export const badOrderNo = (
	<Trans i18nKey="OrderLookup.error.badOrderNo">
		The email address or order number you entered is incorrect. Please try again or contact
		customer support toll free at 1-800-390-6035.
	</Trans>
);

export const badCc = (
	<Trans i18nKey="OrderLookup.error.badCc">
		The email address or last 4 digits of your payment method you entered is incorrect. Please
		try again or contact customer support toll free at 1-800-390-6035.
	</Trans>
);

export const badZip = (
	<Trans i18nKey="OrderLookup.error.badZip">
		The email address or zip/postal code you entered is incorrect. Please try again or contact
		customer support toll free at 1-800-390-6035.
	</Trans>
);

// Error messages related to verification code
export const codeBad = (
	<Trans i18nKey="OrderLookup.error.codeExpiredInvalid">
		This verification code has expired or is invalid. Please enter your order information again.
	</Trans>
);

export const codeTooManyRequests = (
	<Trans i18nKey="OrderLookup.error.tooManyCodeRequests">
		For your safety, we limit the number of verification codes you can request within a certain
		time period. Please try again in one hour.
	</Trans>
);

export const unknownError = (
	<Trans i18nKey="OrderLookup.error.unknown">
		An unknown error has occurred. Please contact customer support toll free at 1-800-390-6035.
	</Trans>
);
