import { gql } from "@apollo/client";

export default gql`
	mutation (
		$receipt: String!
		$lineItemId: Int!
		$ticketId: Int!
		$action: String!
		$comment: String!
	) {
		editTicket(
			receipt: $receipt
			lineItemId: $lineItemId
			ticketId: $ticketId
			action: $action
			editTicketInput: { comment: $comment }
		)
	}
`;
