import { gql } from "@apollo/client";

export default gql`
	query ($email: String!, $endDate: String) {
		ordersByEmail(email: $email, end: $endDate, pageNumber: 0, pageSize: 1) {
			pageInfo {
				pageSize
				pageNumber
				totalElements
				first
				last
			}
			orders {
				receiptNo
				orderDate
				shippingCustomer {
					email
				}
			}
		}
	}
`;
