import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { createContext, useEffect, useState } from "react";

import { FIRST_ORDER, ORDERS_BY_EMAIL, ORDER_BY_RECEIPT } from "../Api";
import { dateRangeOptions } from "../constants/index";
import { getEmailFromToken, getSubFromToken } from "../util/authHelpers";

export const AppContext = createContext({});
let initialLastDate = new Date();
initialLastDate.setMonth(initialLastDate.getMonth() - dateRangeOptions[0]);

let dateCap = new Date();
dateCap.setMonth(dateCap.getMonth() - dateRangeOptions[dateRangeOptions.length - 1]);

export const AppContextProvider = ({ children }) => {
	const [alertText, setAlertText] = useState("");
	const [receiptNo, setReceiptNo] = useState("");
	const [ticketId, setTicketId] = useState(null);
	const [email, setEmail] = useState("");
	const [lastDate, setLastDate] = useState(initialLastDate);
	const [showAlert, setAlert] = useState(false);
	const [isActionSuccessful, setActionSuccessful] = useState(false);
	const [historyLoading, setHistoryLoading] = useState(false);
	const [updatedOrders, setUpdatedOrders] = useState([]);
	const [kountSessionId, setKountSessionId] = useState("");
	const [sub, setSub] = useState("");

	const [
		getOrderByReceipt,
		{
			error: orderByReceiptError,
			data: orderByReceiptData,
			loading: orderByReceiptLoading,
			refetch: refetchOrderByReceipt
		}
	] = useLazyQuery(ORDER_BY_RECEIPT, {
		variables: { receipt: receiptNo },
		fetchPolicy: "network-only"
	});

	const [
		getOrdersByEmail,
		{
			error: ordersHistoryError,
			data: ordersHistoryData,
			loading: ordersHistoryLoading,
			refetch: refetchOrderHistory,
			networkStatus: orderHistoryStatus
		}
	] = useLazyQuery(ORDERS_BY_EMAIL, {
		variables: {
			email: email,
			endDate: lastDate,
			page: 0,
			size: 5000
		},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "network-only",
		nextFetchPolicy: "standby"
	});

	const [
		getFirstOrderByEmail,
		{ error: getFirstOrderError, data: getFirstOrderData, loading: getFirstOrderLoading }
	] = useLazyQuery(FIRST_ORDER, {
		variables: {
			email: email,
			endDate: dateCap
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "standby"
	});

	const getFirstOrder = async () => {
		getFirstOrderByEmail();
	};

	const getOrderByReceiptNo = (receipt, ticketId) => {
		if (receipt) setReceiptNo(receipt);
		setTicketId(ticketId);
		getOrderByReceipt();
	};

	const refetchOrderByReceiptNo = receipt => {
		if (receipt) setReceiptNo(receipt);
		if (!updatedOrders.includes(receipt)) setUpdatedOrders(updatedOrders.concat(receipt));
		try {
			refetchOrderByReceipt();
		} catch (TypeError) {
			getOrderByReceipt();
		}
	};

	const getEmail = async () => {
		const userEmail = await getEmailFromToken();
		setEmail(userEmail);
	};

	const getSub = async () => {
		const userSub = await getSubFromToken();
		setSub(userSub);
	};

	const getOrderHistory = async () => {
		try {
			refetchOrderHistory();
		} catch (TypeError) {
			getOrdersByEmail();
		}
	};

	const appContext = {
		getOrderHistory,
		refetchOrderHistory,
		ordersHistoryData,
		ordersHistoryLoading,
		historyLoading,
		ordersHistoryError,
		orderHistoryStatus,
		getOrderByReceiptNo,
		lastDate,
		setLastDate,
		email,
		ticketId,
		setTicketId,
		orderByReceiptData,
		orderByReceiptError,
		orderByReceiptLoading,
		refetchOrderByReceiptNo,
		alertText,
		setAlertText,
		showAlert,
		setAlert,
		isActionSuccessful,
		setActionSuccessful,
		getEmail,
		updatedOrders,
		setUpdatedOrders,
		kountSessionId,
		setKountSessionId,
		getFirstOrderError,
		getFirstOrderData,
		getFirstOrderLoading,
		getFirstOrder,
		sub
	};

	useEffect(() => {
		setHistoryLoading(ordersHistoryLoading);
	}, [ordersHistoryLoading, setHistoryLoading]);

	useEffect(() => {
		getEmail();
		getSub();
	}, []);

	return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
};

AppContextProvider.propTypes = {
	children: PropTypes.node
};
