import { gql } from "@apollo/client";

export default gql`
	mutation (
		$receipt: String!
		$cardHolderName: String!
		$cvv: String!
		$expirationMonth: Int!
		$expirationYear: Int!
		$cardTokenHash: String!
		$cardToken: String!
		$cardType: String!
		$billingInfo: EditShippingInput!
		$shippingInfo: EditShippingInput
		$kountSessionId: String!
	) {
		editCustomer(
			receipt: $receipt
			kountSessionId: $kountSessionId
			shippingInfo: $shippingInfo
			billingInfo: $billingInfo
			paymentInfo: {
				cardHolderName: $cardHolderName
				cvv: $cvv
				expirationMonth: $expirationMonth
				expirationYear: $expirationYear
				cardTokenHash: $cardTokenHash
				cardToken: $cardToken
				cardType: $cardType
			}
		)
	}
`;
