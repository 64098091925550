import { ApolloProvider } from "@apollo/client";
import { Box, CircularProgress } from "@mui/material";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { ApolloClient } from "./Api";
import { configure } from "./Api/Amplify";
import App from "./App";
import { AppContextProvider } from "./context";
import i18n from "./i18n";

i18n.init().then(() => {
	configure();

	// loading component for suspense fallback
	const Loader = () => (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "40vh"
			}}
		>
			<CircularProgress size={100} thickness={2} />
		</Box>
	);

	const container = document.getElementById("root");
	const root = createRoot(container);

	root.render(
		<React.StrictMode>
			<BrowserRouter>
				<ApolloProvider client={ApolloClient}>
					<AppContextProvider>
						<Suspense fallback={<Loader />}>
							<App />
						</Suspense>
					</AppContextProvider>
				</ApolloProvider>
			</BrowserRouter>
		</React.StrictMode>
	);

	return () => root.unmount();
});
