import { DSPalette } from "@clickbank-ui/seller-design-system";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

const breakpoints = DSBreakpoints.default;
const { palette } = DSPalette;

const upper = RegExp("[A-Z]");
const lower = RegExp("[a-z]");
const number = RegExp("[0-9]");
const special = RegExp("[^A-Za-z0-9]");
const noSpaces = RegExp(/^\S*$/);

const styles = {
	root: {
		padding: "5px 0 16px 18px",

		[breakpoints.up("sm")]: {
			padding: "8px 18px 32px"
		}
	},
	line: {
		display: "flex",
		alignContent: "center",

		"& svg": {
			fontSize: "16px"
		}
	},
	text: {
		paddingLeft: "4px",
		paddingBottom: "6px",
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: 1.3
	}
};

const PasswordValidator = ({ password }) => {
	const validators = [
		{
			validator: lower.test(password),
			text: (
				<Trans i18nKey="PasswordValidator.oneLowerCase">
					At least 1 lower case letter.
				</Trans>
			)
		},
		{
			validator: upper.test(password),
			text: (
				<Trans i18nKey="PasswordValidator.oneUpperCase">
					At least 1 upper case letter.
				</Trans>
			)
		},

		{
			validator: number.test(password),
			text: <Trans i18nKey="PasswordValidator.oneNumber">At least 1 number.</Trans>
		},
		{
			validator: special.test(password),
			text: (
				<Trans i18nKey="PasswordValidator.oneSpecial">
					At least 1 special character (!@#$%^&*/).
				</Trans>
			)
		},
		{
			validator: password && password.length < 33 && password.length > 0,
			text: (
				<Trans i18nKey="PasswordValidator.cannotExceed32">
					Password cannot exceed 32 characters.
				</Trans>
			)
		},
		{
			validator: noSpaces.test(password),
			text: (
				<Trans i18nKey="PasswordValidator.cannotContainSpaces">
					Password cannot contain spaces.
				</Trans>
			)
		}
	];

	return (
		<Box sx={styles.root} data-cy="password-criteria">
			{validators.map(({ validator, text }, index) => (
				<Box key={"validator-" + index} sx={styles.line}>
					{!password || validator ? (
						<CheckCircle
							sx={{ color: !password ? palette.text.disabled : palette.success.dark }}
						/>
					) : (
						<Cancel sx={{ color: palette.error.main }} />
					)}
					<Typography color="textSecondary" sx={styles.text}>
						{text}
					</Typography>
				</Box>
			))}
		</Box>
	);
};

PasswordValidator.propTypes = {
	password: PropTypes.string
};

export default PasswordValidator;
