import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Close } from "@mui/icons-material";
import { DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Alert } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

const { cbNeutral } = DSPalette;

const BuildModalPanel = ({ description, handleCloseModalFn, shippingInfo, title, warning }) => {
	const [isAlertClosed, setAlertClosed] = useState(false);

	const handleCloseAlert = () => {
		setAlertClosed(true);
	};

	const handleCloseModal = () => {
		handleCloseModalFn();
	};

	return (
		<>
			<DialogTitle>
				<Typography sx={{ fontSize: "1.5rem", fontWeight: "400", lineHeight: 1.333 }}>
					{title}
				</Typography>
				<IconButton
					aria-label="close"
					onClick={handleCloseModal}
					size="large"
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: cbNeutral[700]
					}}
				>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent sx={{ display: shippingInfo || isAlertClosed ? "none" : "block" }}>
				<Alert
					severity="warning"
					sx={{ display: warning ? "flex" : "none" }}
					onClose={() => handleCloseAlert()}
				>
					{warning}
					{description}
				</Alert>
			</DialogContent>
		</>
	);
};

BuildModalPanel.propTypes = {
	description: PropTypes.node,
	handleCloseModalFn: PropTypes.func,
	shippingInfo: PropTypes.object,
	title: PropTypes.node,
	warning: PropTypes.node
};

export default BuildModalPanel;
