import { gql } from "@apollo/client";

import { ORDER_DETAILS_INNER } from "./OrderDetailsInner";

export const ORDER_DETAILS = gql`
	fragment OrderDetailsFragment on Order {
		...OrderDetailsInnerFragment
		relatedOrders {
			...OrderDetailsInnerFragment
		}
	}
	${ORDER_DETAILS_INNER}
`;
