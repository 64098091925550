import Auth from "@aws-amplify/auth";

import authConfig from "../config/authConfig";
import i18n from "../i18n";
import { deleteAnonymousToken } from "../util/anonymousTokenStorage";

/**
 * Configures the AWS Auth Library with the given configuration
 */
export const configure = () => {
	const oauth = {
		domain: authConfig.domain,
		scope: authConfig.scope,
		redirectSignIn: authConfig.redirectSignIn,
		redirectSignOut: authConfig.redirectSignOut,
		responseType: "code", // code or token
		grantType: "authorization_code"
	};
	return Auth.configure({
		region: authConfig.cognitoRegion,
		userPoolId: authConfig.userPoolId,
		userPoolWebClientId: authConfig.userPoolWebClientId,
		mandatorySignIn: false,
		storage: authConfig.storage,
		oauth,
		clientMetadata: { language: i18n.language }
	});
};

export const signUp = async ({ email, password, name, token }) =>
	Auth.signUp({
		username: email,
		password,
		attributes: {
			name,
			email
		},
		// must include language when overriding default clientMetadata
		clientMetadata: {
			token,
			language: i18n.language
		}
	});

/**
 * resendSignUp
 */
export const resendSignUp = username => {
	return Auth.resendSignUp(username);
};

export const confirmSignUp = (username, code) => Auth.confirmSignUp(username, code);

/**
 * Signs in the user to AWS Cognito
 * @param {string} username
 * @param {string} password
 */
export const signIn = (username, password, token) => Auth.signIn(username, password, { token });

/**
 * Signs out the user from Cognito
 */
export const signOut = async () => {
	try {
		await Auth.signOut({ global: true });
	} catch (error) {
		await Auth.signOut();
	}
	sessionStorage.clear();
	deleteAnonymousToken(); // make sure we trigger the auth change event
};

/**
 * Get the users current session if signed in.
 */
export const getCurrentSession = () => {
	return Auth.currentSession();
};

/**
 * Send a request password reset email to the account
 * @param {string} email
 */
export const requestPasswordReset = email => {
	return Auth.forgotPassword(email);
};

/**
 * Submit the users new password given the code that was sent via email
 * @param {string} username The username of the user likely an email
 * @param {string} code The code emailed to the user to verify identity
 * @param {string} newPassword The password the user would like to use
 */
export const forgotPasswordSubmit = (username, code, newPassword) => {
	return Auth.forgotPasswordSubmit(username, code, newPassword);
};

/**
 * Returns a valid idToken for the current user
 * If the token is expired it will be automatically refreshed
 */
export const getToken = async () => {
	try {
		let currentSession = await Auth.currentSession();

		//Refresh token if expired
		if (isTokenExpired(currentSession)) {
			//@ts-ignore
			currentSession = await refreshToken(currentSession);
		}

		return currentSession.getAccessToken().getJwtToken();
	} catch (error) {
		return;
	}
};

/**
 * This will refresh the current session tokens if the token is due to expire in the next 5 minutes
 *
 * @param {CognitoUserSession} currentSession
 * @returns {boolean}
 */
export const isTokenExpired = currentSession => {
	const tokenExp = currentSession.getAccessToken().getExpiration();
	return tokenExp <= Date.now() / 1000 + 300;
};

/**
 * Refreshes the current users session with new access and id tokens
 * This is done automatically in getToken() so you might not need to call this
 * @param {CognitoUserSession} currentSession
 */
export const refreshToken = currentSession => {
	return new Promise((resolve, reject) => {
		Auth.currentAuthenticatedUser()
			.then(currentUser => {
				currentUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
					if (err !== null) reject(err);
					else resolve(session);
				});
			})
			.catch(err => {
				reject(err);
			});
	});
};

/**
 * Allows the currently logged in user to reset their password.
 */
export const changePassword = async (currentPassword, newPassword) => {
	let user = await Auth.currentAuthenticatedUser();
	return Auth.changePassword(user, currentPassword, newPassword);
};

export const getCurrentUserDetails = () => Auth.currentUserInfo();

export const updateUserAttributes = async attributes => {
	let user;
	user = await Auth.currentAuthenticatedUser();
	return Auth.updateUserAttributes(user, { ...user.attributes, ...attributes });
};
