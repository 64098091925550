import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

const { cbBlue } = DSPalette;

const styles = {
	userCommentParent: {
		flexDirection: "column",
		alignContent: "flex-end"
	},
	userComment: {
		backgroundColor: cbBlue[950],
		padding: "20px",
		borderRadius: "16px 16px 0px 16px",
		maxWidth: "67%",
		overflowWrap: "anywhere"
	},
	userCommentUser: {
		alignSelf: "flex-end",
		marginRight: "16px",
		fontWeight: "700",
		fontSize: "16px"
	},
	commentUser: {
		fontWeight: "700",
		fontSize: "1rem"
	}
};

const UserComment = ({ comment }) => {
	return (
		<Grid item container sx={styles.userCommentParent}>
			<Grid item sx={styles.userCommentUser}>
				<Typography sx={styles.commentUser}>
					<Trans i18nKey="TicketDiscussion.User">You</Trans>
				</Typography>
			</Grid>
			<Grid item sx={styles.userComment}>
				<Typography variant="body2">{comment}</Typography>
			</Grid>
		</Grid>
	);
};

UserComment.propTypes = {
	comment: PropTypes.string
};

export default UserComment;
