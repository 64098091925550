import { gql } from "@apollo/client";

export default gql`
	query {
		supportAvailability {
			phone {
				currentlyAvailable
			}
			chat {
				currentlyAvailable
			}
		}
	}
`;
