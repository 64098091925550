import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const { cbNeutral } = DSPalette;

const styles = {
	vendorCommentParent: {
		flexDirection: "column",
		alignContent: "flex-start"
	},
	vendorComment: {
		backgroundColor: cbNeutral["950"],
		padding: "20px",
		borderRadius: "16px 16px 16px 0px",
		maxWidth: "67%",
		overflowWrap: "anywhere"
	},
	vendorCommentUser: {
		alignSelf: "flex-start",
		marginLeft: "16px",
		fontWeight: "700"
	},
	commentUser: {
		fontWeight: "700",
		fontSize: "1rem"
	}
};

const VendorComment = ({ role, comment }) => {
	return (
		<Grid item container sx={styles.vendorCommentParent}>
			<Grid item sx={styles.vendorCommentUser}>
				<Typography sx={styles.commentUser}>{role}</Typography>
			</Grid>
			<Grid item sx={styles.vendorComment}>
				<Typography variant="body2">{comment}</Typography>
			</Grid>
		</Grid>
	);
};

VendorComment.propTypes = {
	role: PropTypes.node,
	comment: PropTypes.node
};

export default VendorComment;
