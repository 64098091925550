/* eslint-disable react/prop-types */
import { TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

/**
 * Simple RHF wrapper for MUI TextFields (helps avoid boilerplate and forgetting to set error/helperText)
 */
export function ControlledTextField({ component, control, name, ...props }) {
	const Component = component ?? TextField;
	return (
		<Controller
			control={control}
			name={name}
			render={({ field, fieldState }) => (
				<Component
					{...field}
					{...props}
					onChange={e => {
						field.onChange(e);
						props.onChange?.(e);
					}}
					error={!!fieldState?.error || props.error}
					helperText={fieldState?.error?.message || props.helperText}
				/>
			)}
		/>
	);
}
