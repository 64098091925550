import { useQuery } from "@apollo/client";

import { IS_FEATURE_ENABLED } from "../Api";

function IsFeatureEnabledForUser(featureName, username) {
	const { loading, error, data } = useQuery(IS_FEATURE_ENABLED, {
		variables: { featureName, username }
	});

	if (loading) return "loading";
	if (error) {
		console.log(error.message);
		return "error";
	}

	return data.isFeatureEnabled;
}

export default IsFeatureEnabledForUser;
