import { gql } from "@apollo/client";

import { ORDER_DETAILS } from "./OrderDetails";

//email:"david.mclean@clickbank.com", end: "2020-06-01T15:05:52Z", pageSize: 5, pageNumber: 0
export default gql`
	query ($email: String!, $endDate: String, $page: Int, $size: Int) {
		ordersByEmail(email: $email, end: $endDate, pageNumber: $page, pageSize: $size) {
			pageInfo {
				pageSize
				pageNumber
				totalElements
				first
				last
			}
			orders {
				...OrderDetailsFragment
			}
		}
	}
	${ORDER_DETAILS}
`;
