import { Hub } from "aws-amplify";

const tokenName = "anonymousToken";

export const setAnonymousToken = value => {
	sessionStorage.setItem(tokenName, value);
	Hub.dispatch("auth", { event: "anonymousToken" });
	return tokenName;
};

export const getAnonymousToken = () => sessionStorage.getItem(tokenName);

export const deleteAnonymousToken = () => {
	sessionStorage.removeItem(tokenName);
	Hub.dispatch("auth", { event: "anonymousToken" });
};
