import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n.use(detector)
	.use(backend)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		returnEmptyString: false,
		backend: { loadPath: `/locales/{{lng}}.json` }, // eslint-disable-line quotes
		supportedLngs: ["en", "de", "es", "fr", "it", "pt"],
		fallbackLng: "en", // use en if detected lng is not available
		debug: false, // Use this to see what's going on in console
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
