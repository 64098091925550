import { DSPalette } from "@clickbank-ui/seller-design-system";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { Box, Button, Fade, Grid, Typography } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import assetPath from "../../config/config.js";
import { isAuthenticated } from "../../util/authHelpers";

const breakpoints = DSBreakpoints.default;
const { palette } = DSPalette;

const Image404 = assetPath.concat("logo/404Image.png");

const styles = {
	root: {
		background: palette.background.default,
		padding: "25px 0px",
		[breakpoints.down("md")]: { padding: 0 }
	},
	fontHeader: {
		[breakpoints.down("md")]: {
			textAlign: "center"
		}
	},
	paragraph: {
		marginTop: "16px",
		[breakpoints.down("md")]: {
			textAlign: "center"
		}
	},
	textContainerBox: {
		zIndex: "2",
		paddingRight: "65px",
		display: "flex",
		justifyContent: "center",
		alignContent: "center",
		flexDirection: "column",
		maxWidth: 700,
		marginRight: "auto",
		marginLeft: "-50px",
		[breakpoints.down("md")]: {
			alignItems: "center",
			marginLeft: "24px",
			marginRight: "24px",
			marginTop: "36.41px",
			paddingRight: "0px"
		}
	},
	button: {
		width: "76.9%",
		height: "64px",
		marginTop: "9%",
		[breakpoints.down("md")]: {
			width: "100%"
		}
	},
	viewGrid: {
		minWidth: "350px",

		[breakpoints.down("lg")]: {
			marginRight: "0px"
		}
	},
	imageContainer: {
		margin: "0 -20px",
		minWidth: "350px",
		alignSelf: "center",
		zIndex: 1,
		[breakpoints.up("md")]: {
			width: "50%",
			maxWidth: "50%",
			margin: "0 0 0 -50px"
		}
	}
};

export default () => {
	const history = useHistory();

	return (
		<Grid container sx={styles.root}>
			<Grid sx={styles.imageContainer} item>
				<Fade timeout={800} in>
					<img src={Image404} alt="error" width="100%" />
				</Fade>
			</Grid>
			<Grid sx={styles.viewGrid} item xs>
				<Box display="flex" height="100%" alignItems="center">
					<Box display="flex" sx={styles.textContainerBox}>
						<Typography variant="h2" sx={styles.fontHeader}>
							<Trans i18nKey="404Page.pageNotFound">
								This page could not be found.
							</Trans>
						</Typography>
						<Typography variant="body1" sx={styles.paragraph}>
							<Trans i18nKey="404Page.paragraphText">
								The page you are looking for cannot be found.
							</Trans>
						</Typography>

						<Button
							onClick={() => history.push("/")}
							sx={styles.button}
							id="returnButton"
						>
							{isAuthenticated() ? (
								<Trans i18nKey="404Page.returnToOrderHistory">
									Return To Order History
								</Trans>
							) : (
								<Trans i18nKey="404Page.returnToOrderLookup">
									Return To Order Lookup
								</Trans>
							)}
						</Button>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};
