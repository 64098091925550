import { DSBreakpoints, DSPalette, DSTypography } from "@clickbank-ui/seller-design-system";
import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

import "../../App.css";
import { ReactComponent as DotsIcon } from "../../assets/Dots.svg";
import { ReactComponent as SlashIcon } from "../../assets/Slash.svg";

const breakpoints = DSBreakpoints.default;
const { cbNeutral } = DSPalette;
const { fontWeightSemi } = DSTypography;

const styles = {
	root: {
		marginTop: "72px",
		padding: "1rem 0 1rem",
		background: "linear-gradient(92deg, rgba(218, 213, 255, 0.56) 0%, #CDD6FF 219.88%)",

		[breakpoints.up("md")]: {
			marginTop: "88px"
		}
	},
	container: {
		position: "relative"
	},
	dots: {
		position: "absolute"
	},
	dotsOne: {
		opacity: 0.3,
		bottom: -2,
		left: -100,

		[breakpoints.down("md")]: {
			display: "none"
		}
	},
	dotsTwo: {
		display: "none",

		[breakpoints.up("md")]: {
			display: "block",
			top: -26,
			right: 50
		}
	},
	slash: {
		display: "none",
		position: "absolute",
		bottom: 0,

		[breakpoints.up("sm")]: {
			display: "block",
			right: 20
		},
		[breakpoints.up("md")]: {
			right: 80
		}
	},
	textColumn: {
		width: "100%",
		maxWidth: 880,
		lineHeight: 1.65,

		[breakpoints.up("sm")]: {
			width: "90%"
		},
		[breakpoints.up("md")]: {
			width: "75%"
		},
		"& *": {
			color: `${cbNeutral[100]} !important` // I had to :(
		},
		"& h1": {
			padding: "0 0 20px",
			fontWeight: fontWeightSemi
		},
		"& h2": {
			paddingBottom: ".5em",
			fontSize: 18,
			fontWeight: fontWeightSemi
		}
	}
};

const CustomerSupport = () => {
	return (
		<Box sx={styles.root}>
			<Container maxWidth="lg" sx={styles.container} data-cy="form-fields-container">
				<Box sx={styles.slash}>
					<SlashIcon />
				</Box>
				<Box sx={[styles.dots, styles.dotsOne]}>
					<DotsIcon />
				</Box>
				<Box sx={[styles.dots, styles.dotsTwo]}>
					<DotsIcon />
				</Box>
				<Box sx={styles.textColumn}>
					<Typography variant="h2" as="h1">
						<Trans i18nKey="CustomerSupport.heading">
							CLKBANK Charges & Customer Service
						</Trans>
					</Typography>
					<Typography variant="h5" as="h2">
						<Trans i18nKey="CustomerSupport.description.p1">
							ClickBank sells digital and physical products created by passionate
							entrepreneurs all over the world.
						</Trans>
					</Typography>

					<Typography variant="body2">
						<Trans i18nKey="CustomerSupport.description.p2">
							If you see a charge from ClickBank (i.e. CLKBANK*) on your bill or
							statement, it means that you or a family member purchased from an online
							merchant that uses ClickBank to process their orders.
						</Trans>
					</Typography>
				</Box>
			</Container>
		</Box>
	);
};

export default CustomerSupport;
