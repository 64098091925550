import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { ChevronLeft } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { forgotPasswordSubmit } from "../../Api/Amplify";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import PasswordValidator from "../../components/PasswordValidator/PasswordValidator";
import assetPath from "../../config/config.js";
import { passwordRegex } from "../../util/validation";

const breakpoints = DSBreakpoints.default;

const styles = {
	overLapGrid: {
		minHeight: "270px",
		marginLeft: " -70px",
		marginRight: "-60px"
	},
	textBox: {
		zIndex: 4,
		width: "100%",

		[breakpoints.up("sm")]: {
			maxWidth: "580px"
		},
		"& .MuiAlert-root": {
			marginTop: "2rem"
		}
	},
	textFieldBox: {
		width: "100%",

		[breakpoints.up("sm")]: {
			width: "480px"
		},
		"& .backButton": {
			marginTop: "16px"
		}
	}
};

export default () => {
	const history = useHistory();

	const logo60 = assetPath.concat("images/Frame_60_1.png");

	const [loading, setLoading] = useState(false);

	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");

	const [newPassword, setNewPassword] = useState("");
	const [newPasswordTouched, setNewPasswordTouched] = useState(false);
	const [isEmpty, setIsEmpty] = useState(true);
	const [newPasswordValid, setNewPasswordValid] = useState(true);
	const [newPasswordHasError, setNewPasswordHasError] = useState(false);

	const handleChange = e => {
		setNewPassword(e.target.value);
		setIsEmpty(e.target.value.length === 0);
		setNewPasswordTouched(true);
		setNewPasswordHasError(false);
		setNewPasswordValid(RegExp(passwordRegex).test(newPassword));
	};

	const handleBlur = () => {
		RegExp(passwordRegex).test(newPassword)
			? setNewPasswordValid(true)
			: setNewPasswordHasError(true);
	};

	const onSubmit = async () => {
		setLoading(true);
		try {
			await forgotPasswordSubmit(email, code, newPassword);
			return history.push(
				`/login?status=passwordResetSuccess&email=${encodeURIComponent(email)}`
			);
		} catch ({ code, message }) {
			return history.push(
				`/login?status=passwordResetFailed&email=${encodeURIComponent(email)}`
			);
		}
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const email = urlParams.get("email");
		const code = urlParams.get("code");
		if (email && code) {
			setEmail(email);
			setCode(code);
		} else {
			history.push("/login?status=passwordResetFailed");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return email && code ? (
		<Grid container>
			<Grid item sx={styles.textBox} sm>
				<Typography variant="h2" as="h1">
					<Trans i18nKey="Common.newPassword">New Password</Trans>
				</Typography>
				<Typography variant="body1" sx={{ marginTop: "20px" }}>
					<Trans i18nKey="VerifyPasswordReset.enterNewPassword">
						Enter a new password for your account.
					</Trans>
				</Typography>

				<Box sx={styles.textFieldBox}>
					<PasswordInput
						id="new-password"
						label={<Trans i18nKey="Common.newPassword">New Password</Trans>}
						value={newPassword}
						onChange={handleChange}
						onBlur={handleBlur}
						error={(newPasswordTouched && isEmpty) || newPasswordHasError}
						helperText={
							<Trans i18nKey="CreateAccountPage.passwordHelperText">
								Please create a password that includes the following criteria:
							</Trans>
						}
						onKeyDown={e => {
							if (e.key === "Enter" && !loading && newPasswordValid) {
								onSubmit();
							}
						}}
					/>

					<PasswordValidator password={newPassword} />

					<Grid container>
						<LoadingButton
							fullWidth
							id="savePasswordButton"
							disabled={loading || !newPasswordValid}
							onClick={onSubmit}
							loading={loading}
						>
							<Trans i18nKey="VerifyPasswordReset.save">Save</Trans>
						</LoadingButton>
						<Button
							fullWidth
							color="secondary"
							className="backButton"
							startIcon={<ChevronLeft />}
							id="backtoLogin"
							onClick={() => {
								history.push("/login");
							}}
						>
							<Trans i18nKey="Common.backToLogin">Back to Login</Trans>
						</Button>
					</Grid>
				</Box>
			</Grid>

			<Grid item sx={{ display: { xs: "none", md: "flex" } }} sm>
				<Box sx={styles.overLapGrid}>
					<img src={logo60} alt="Customer Care" width="100%" height="100%"></img>
				</Box>
			</Grid>
		</Grid>
	) : (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "60vh"
			}}
		>
			<CircularProgress size={100} thickness={2} />
		</Box>
	);
};
