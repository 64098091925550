import { useMutation } from "@apollo/client";
import { DSPalette } from "@clickbank-ui/seller-design-system";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Typography
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Trans } from "react-i18next";

import { ApolloClient, EDIT_TICKET, ESCALATE_TICKET } from "../../Api";
import {
	editTicketActions,
	editTicketMessages,
	statusBarColor,
	statusTextColor,
	supportTicketStatus,
	supportTicketTopic,
	supportTicketType
} from "../../constants";
import { AppContext } from "../../context/AppContext";
import { getTicketStatus } from "../../util/orderHelpers";
import RelatedTickets from "./RelatedTickets";
import TicketDiscussionThread from "./TicketDiscussionThread/TicketDiscussionThread";
import ViewTicketActions from "./ViewTicketActions";

const breakpoints = DSBreakpoints.default;
const { cbNeutral } = DSPalette;

const styles = {
	description: {
		display: "flex",
		marginTop: "16px",
		flexDirection: "row",
		[breakpoints.down("md")]: {
			flexDirection: "column"
		}
	},
	itemDescription: {
		paddingLeft: "12px",
		[breakpoints.down("md")]: {
			padding: "8px 0px 0px"
		}
	},
	titleDiv: {
		display: "flex",
		width: "100%",
		marginBottom: "12px",
		borderTopLeftRadius: "4px",
		borderTopRightRadius: "4px",
		boxSizing: "border-box",
		padding: "16px 24px",
		fontWeight: "600"
	},
	ticketStatus: {
		display: "flex",
		justifyContent: "flex-end",
		alignSelf: "center",
		[breakpoints.down("md")]: {
			justifyContent: "flex-start",
			marginTop: "8px",
			marginBottom: "16px",
			width: "100%"
		}
	}
};

const ViewTicketPanel = ({ handleClose, action, ticket, relatedTickets, lineItem, receipt }) => {
	const [ticketAction, setTicketAction] = useState(action ? action : "");
	const [currentTicket, setCurrentTicket] = useState(ticket);
	const [currentLineItem, setCurrentLineItem] = useState(lineItem);
	const [currentReceipt, setCurrentReceipt] = useState(receipt);
	const [comment, setComment] = useState("");

	const ticketDate = () => new Date(currentTicket?.createTime).toLocaleDateString("en-US");
	const { setAlertText, setAlert, setActionSuccessful, refetchOrderByReceiptNo } =
		useContext(AppContext);

	const isRefundRequestable =
		!currentLineItem.refunded && currentLineItem.refundable && !currentLineItem.shippingNotice;
	const isSubscriptionCancelable =
		currentLineItem.paymentPlan?.activeStatus === "ACTIVE" && !currentLineItem.refunded;
	const isTicketProcessed =
		currentLineItem?.refunded || currentLineItem?.paymentPlan?.activeStatus === "CANCELED";
	const status = getTicketStatus(currentTicket, isTicketProcessed);

	const handleError = () => {
		handleClose();
		setAlertText(
			<Trans i18nKey="Common.error.unknown">
				An unknown error has occurred. Please contact customer support directly.
			</Trans>
		);
		setAlert(true);
		setActionSuccessful(false);
	};

	const handleEditSuccess = () => {
		handleClose();
		setAlertText(editTicketMessages[ticketAction]);
		setActionSuccessful(true);
		setAlert(true);
		refetchOrderByReceiptNo(receipt);
	};

	const [editTicket, { loading: editLoading }] = useMutation(EDIT_TICKET, {
		variables: {
			receipt: currentReceipt,
			lineItemId: parseInt(currentLineItem.id, 10),
			ticketId: parseInt(currentTicket.id, 10),
			action: editTicketActions[ticketAction],
			comment
		},
		client: ApolloClient,
		onError: handleError,
		onCompleted: handleEditSuccess
	});

	const [escalateTicket, { loading: escalateLoading }] = useMutation(ESCALATE_TICKET, {
		variables: {
			receipt: currentReceipt,
			lineItemId: parseInt(currentLineItem.id, 10),
			ticketId: parseInt(currentTicket.id, 10),
			comment
		},
		client: ApolloClient,
		onError: handleError,
		onCompleted: handleEditSuccess
	});

	const handleChangeTicketAction = event => {
		setTicketAction(event.target.value);
	};

	const handleChangeComment = event => {
		const { value } = event.target;
		if (!value) return setComment("");
		let formattedValue = value;
		if (formattedValue.length > 500) formattedValue = formattedValue.slice(0, 500);
		setComment(formattedValue);
	};
	const isSendinAllowed = new RegExp(/[a-zA-Z0-9-./'#,":{}()!@%^&*]+/).test(comment);

	return (
		<>
			<DialogTitle>
				<Typography sx={{ fontSize: "2rem", fontWeight: 600, lineHeight: 1.25 }}>
					<Trans i18nKey="SupportTicket.titleSupportTicket">Support Ticket:</Trans> #
					{currentTicket.id}
				</Typography>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					size="large"
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: cbNeutral[700]
					}}
				>
					<Close />
				</IconButton>
			</DialogTitle>

			<Typography
				variant="h5"
				sx={{
					...styles.titleDiv,

					background: statusBarColor[status],
					color: statusTextColor[status]
				}}
			>
				<Trans i18nKey="ViewTicketPanel.headerStatus">Status</Trans>:{" "}
				{supportTicketStatus[status]}
			</Typography>

			<DialogContent>
				<Typography>
					<Trans i18nKey="ViewTicketPanel.para1">
						Thank you for contacting us. We have notified the vendor of your support
						request. If you need assistance with a different order, please look up the
						details for that order and create a separate ticket.
					</Trans>
				</Typography>
				<Typography>
					<Trans i18nKey="ViewTicketPanel.para2">
						You may return here to view the status of your ticket or communicate with
						the vendor. If your issue has been resolved, please close the ticket.
					</Trans>
				</Typography>

				<Divider sx={{ marginTop: "16px" }} />

				{relatedTickets && relatedTickets.length > 1 && (
					<div id="relatedTicketsParent">
						<RelatedTickets
							currentTicketId={currentTicket.id}
							setCurrentTicket={setCurrentTicket}
							relatedTickets={relatedTickets}
							setCurrentLineItem={setCurrentLineItem}
							setCurrentReceipt={setCurrentReceipt}
							setTicketAction={setTicketAction}
							setComment={setComment}
						/>
						<Divider sx={{ marginTop: "16px" }} />
					</div>
				)}
				<div id="descriptionParent">
					<Box sx={styles.description}>
						<div>
							<Typography sx={{ color: cbNeutral["400"] }}>
								<strong>
									<Trans i18nKey="ViewTicketPanel.ticketType">Ticket Type</Trans>:
								</strong>
							</Typography>
						</div>
						<Box sx={styles.itemDescription}>
							<Typography>{supportTicketType[currentTicket?.ticketType]}</Typography>
						</Box>
					</Box>
					<Box sx={styles.description}>
						<div>
							<Typography sx={{ color: cbNeutral["400"] }}>
								<strong>
									<Trans i18nKey="ViewTicketPanel.supportTopic">
										Support Topic
									</Trans>
									:
								</strong>
							</Typography>
						</div>
						<Box sx={styles.itemDescription}>
							<Typography>{supportTicketTopic[currentTicket.topic]}</Typography>
						</Box>
					</Box>
					<Box sx={styles.description}>
						<div>
							<Typography sx={{ color: cbNeutral["400"] }}>
								<strong>
									<Trans i18nKey="ViewTicketPanel.created">Created</Trans>:
								</strong>
							</Typography>
						</div>
						<Box sx={styles.itemDescription}>
							<Typography>{ticketDate()}</Typography>
						</Box>
					</Box>
					<Box sx={styles.description}>
						<div>
							<Typography sx={{ color: cbNeutral["400"] }}>
								<strong>
									<Trans i18nKey="ViewTicketPanel.status">Status</Trans>:
								</strong>
							</Typography>
						</div>
						<Box sx={styles.itemDescription}>
							<Typography>{supportTicketStatus[status]}</Typography>
						</Box>
					</Box>
				</div>
				<div>
					<TicketDiscussionThread ticket={currentTicket} lineItem={currentLineItem} />
				</div>
			</DialogContent>

			{status !== "PROCESSED" && (
				<>
					<DialogContent>
						<ViewTicketActions
							ticketAction={ticketAction}
							handleChangeTicketAction={handleChangeTicketAction}
							ticketType={currentTicket.ticketType}
							isRefundRequestable={isRefundRequestable}
							isSubscriptionCancelable={isSubscriptionCancelable}
							comment={comment}
							handleChangeComment={handleChangeComment}
							status={currentTicket.status}
							editLoading={editLoading}
							escalateLoading={escalateLoading}
						/>
					</DialogContent>

					<DialogActions>
						<Button color="secondary" onClick={handleClose}>
							<Trans i18nKey="ViewTicketPanel.cancel">Cancel</Trans>
						</Button>
						<LoadingButton
							disabled={
								!ticketAction ||
								(ticketAction === "escalate" && !comment) ||
								editLoading ||
								escalateLoading ||
								!isSendinAllowed
							}
							onClick={() => {
								ticketAction === "escalate" ? escalateTicket() : editTicket();
							}}
							loading={editLoading || escalateLoading}
						>
							<Trans i18nKey="ViewTicketPanel.send">Send</Trans>
						</LoadingButton>
					</DialogActions>
				</>
			)}
		</>
	);
};

ViewTicketPanel.propTypes = {
	handleClose: PropTypes.func,
	action: PropTypes.oneOf([
		"",
		"reply",
		"close",
		"techSupport",
		"requestRefund",
		"cancelSubscription",
		"reopen",
		"escalate"
	]),
	ticket: PropTypes.object,
	lineItem: PropTypes.object,
	status: PropTypes.oneOf(["OPEN", "REOPENED", "CLOSED", "ESCALATED", "PROCESSED"]),
	receipt: PropTypes.string,
	relatedTickets: PropTypes.arrayOf(PropTypes.object)
};

export default ViewTicketPanel;
