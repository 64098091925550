import { gql } from "@apollo/client";

export default gql`
	mutation ($receipt: String!, $lineItemId: Int!, $ticketId: Int!, $comment: String!) {
		escalateTicket(
			receipt: $receipt
			lineItemId: $lineItemId
			ticketId: $ticketId
			action: "ESCALATE"
			editTicketInput: { comment: $comment }
		)
	}
`;
