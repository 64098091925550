import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

const { palette } = DSPalette;

const AccessProductTooltip = () => {
	return (
		<Tooltip
			placement="top-end"
			enterTouchDelay={0} // for mobile
			disableTouchListener // for mobile
			title={
				<>
					<Typography variant="h5">
						<Trans i18nKey="AccessProductTooltip.cantAccess">
							Can't access this product?
						</Trans>
					</Typography>
					<Typography>
						<Trans i18nKey="AccessProductTooltip.p1">
							Your access may have expired or reached a maximum download limit.
						</Trans>
					</Typography>
					<Typography>
						<Trans i18nKey="AccessProductTooltip.p2">
							If it is no longer working, you may reach out to the vendor by creating
							a Tech Support ticket below.
						</Trans>
					</Typography>
				</>
			}
		>
			<Typography
				sx={{
					margin: "4px 0 -8px",
					fontSize: 14,
					textAlign: "right",
					color: palette.primary.main,
					cursor: "pointer"
				}}
			>
				<Trans i18nKey="OrderDetails.cantAccess">I can't access this product</Trans>
			</Typography>
		</Tooltip>
	);
};

export default AccessProductTooltip;
