import { gql } from "@apollo/client";

export default gql`
	query ($vendorId: String!) {
		siteSettings(vendorId: $vendorId, filter: "order") {
			customerService {
				brand
				website
				email
				phone
				extension
				address1
				address2
				city
				state
				countryCode
				zip
				residential
			}
		}
	}
`;
