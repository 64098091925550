const { default: i18n } = require("../i18n");
// "es", "fr", "it", "pt"
export const currencyFormatter = (number, currency) => {
	const { language } = i18n;
	switch (language) {
		case "de":
			return new Intl.NumberFormat("de", {
				style: "currency",
				currency: currency ? currency : "EUR"
			}).format(number);

		case "es":
			return new Intl.NumberFormat("es", {
				style: "currency",
				currency: currency ? currency : "EUR"
			}).format(number);

		case "it":
			return new Intl.NumberFormat("it", {
				style: "currency",
				currency: currency ? currency : "EUR"
			}).format(number);

		case "pt":
			return new Intl.NumberFormat("pt", {
				style: "currency",
				currency: currency ? currency : "BRL"
			}).format(number);

		case "fr":
			return new Intl.NumberFormat("fr", {
				style: "currency",
				currency: currency ? currency : "EUR"
			}).format(number);

		default:
			return new Intl.NumberFormat("en", {
				style: "currency",
				currency: currency ? currency : "USD"
			}).format(number);
	}
};
