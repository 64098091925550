import { DSBreakpoints, DSPalette } from "@clickbank-ui/seller-design-system";
import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

import { ReactComponent as PackageIcon } from "../../../assets/PackageIcon.svg";
import { ReactComponent as ShippingIcon } from "../../../assets/ShippingIcon.svg";
import { ReactComponent as StoreIcon } from "../../../assets/StoreIcon.svg";

const breakpoints = DSBreakpoints.default;
const { cbNeutral, palette } = DSPalette;

const styles = {
	shippingStatus: {
		position: "relative",
		marginTop: ".75rem",
		marginBottom: "1rem",

		[breakpoints.up("lg")]: {
			flexDirection: "row",
			marginTop: "2rem"
		},
		"&::before, &::after": {
			content: '""',
			position: "absolute",
			left: 11,
			zIndex: 0,
			width: "1px",
			height: "50%",

			[breakpoints.up("lg")]: {
				width: "calc(50% - 30px)",
				height: "1px"
			}
		},
		"&::before": {
			top: 0,
			background: palette.primary.main,

			[breakpoints.up("lg")]: {
				left: 50,
				top: 18
			}
		},
		"&::after": {
			top: "50%",
			background: palette.primary.main,

			[breakpoints.up("lg")]: {
				left: "50%",
				top: 18,
				width: "calc(50% - 50px)",
				height: "1px"
			},
			".notShipped &": {
				background: cbNeutral[700]
			}
		},
		"& .MuiStack-root": {
			display: "flex",
			flexDirection: "row",
			zIndex: 10,

			[breakpoints.up("lg")]: {
				flexDirection: "column",
				alignItems: "center",
				maxWidth: "80px",
				textAlign: "center"
			},
			"& .MuiTypography-h5": {
				color: palette.primary.main,
				lineHeight: 1.2
			},
			"&.notShipped": {
				"& .MuiTypography-h5": {
					color: cbNeutral[400]
				},
				"& svg": {
					borderColor: cbNeutral[400],

					"& path": {
						fill: cbNeutral[400]
					}
				}
			},
			"& svg": {
				marginRight: "4px",
				padding: "3px",
				background: palette.common.white,
				border: `solid 1px ${palette.primary.main}`,
				borderRadius: "50%",

				[breakpoints.up("lg")]: {
					width: 24,
					height: 24,
					marginRight: 0,
					padding: "6px"
				},
				"& path": {
					fill: palette.primary.main
				}
			}
		}
	}
};

const ShippingStatus = ({ shipped }) => {
	return (
		<Stack sx={styles.shippingStatus} columnGap={5} rowGap={1.75}>
			<Stack>
				<StoreIcon />
				<Typography
					variant="h5"
					fontWeight={600}
					data-cy="shipping-status-order-received"
					as="h4"
				>
					<Trans i18nKey="ShippingStatus.orderReceived">Order Received</Trans>
				</Typography>
			</Stack>
			<Stack>
				<PackageIcon />
				<Typography
					variant="h5"
					fontWeight={600}
					data-cy="shipping-status-awaiting-fulfillment"
					as="h4"
				>
					<Trans i18nKey="ShippingStatus.awaitingFulfillment">Awaiting Fulfillment</Trans>
				</Typography>
			</Stack>
			<Stack className={shipped === false && "notShipped"} aria-disabled={shipped === false}>
				<ShippingIcon />
				<Typography variant="h5" fontWeight={600} data-cy="shipping-status-shipped" as="h4">
					<Trans i18nKey="ShippingStatus.shipped">Shipped</Trans>
				</Typography>
			</Stack>
		</Stack>
	);
};

ShippingStatus.propTypes = {
	currencyCode: PropTypes.string,
	lineItem: PropTypes.object,
	shipped: PropTypes.bool
};

export default ShippingStatus;
