import { DSBreakpoints, DSPalette } from "@clickbank-ui/seller-design-system";
import { Divider, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const breakpoints = DSBreakpoints.default;
const { cbNeutral } = DSPalette;

const styles = {
	statusChangeParent: {
		flexDirection: "row",
		margin: "25px 0",
		[breakpoints.down("md")]: {
			margin: "8px 0"
		}
	},
	statusComment: {
		margin: "0 25px",
		maxWidth: "80%",
		textAlign: "center",
		[breakpoints.down("md")]: {
			margin: "0 8px"
		}
	},
	statusDivider: {
		margin: "auto 0",
		flex: "1"
	}
};

const StatusChange = ({ comment }) => {
	return (
		<Grid item container sx={styles.statusChangeParent}>
			<Grid item sx={styles.statusDivider}>
				<Divider />
			</Grid>
			<Grid item sx={styles.statusComment}>
				<Typography sx={{ fontSize: "1rem", color: cbNeutral["200"] }}>
					{comment}
				</Typography>
			</Grid>
			<Grid item sx={styles.statusDivider}>
				<Divider />
			</Grid>
		</Grid>
	);
};

StatusChange.propTypes = {
	comment: PropTypes.node
};

export default StatusChange;
